import {useListView} from '../../core/ListViewProvider'
import {TransactionsListToolbar} from './TransactionListToolbar'
import {TransactionsListGrouping} from './TransactionsListGrouping'
import {TransactionsListSearchComponent} from './TransactionsListSearchComponent'

const TransactionsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header btransaction-0 pt-6'>
      <TransactionsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar d-flex col-7 col-sm-8'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <TransactionsListGrouping /> : <TransactionsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TransactionsListHeader}
