import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialAdvertisement, Advertisement } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { AdvertisementsListLoading } from '../components/loading/AdvertisementsListLoading'
import { createAdvertisement, updateAdvertisement } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'

type Props = {
  isAdvertisementLoading: boolean
  advertisement: Advertisement
}

const editAdvertisementSchema = Yup.object().shape({
  ad_slider_image_url: Yup.mixed()
    .test('fileType', 'Invalid file type. Only PNG, JPG, or JPEG allowed.', (value) => {
      if (value && value.length) {
        const fileType = value[0].type;
        return (
          fileType === 'image/png' ||
          fileType === 'image/jpg' ||
          fileType === 'image/jpeg'
        );
      }
      return true;
    })
    .required('Advertisement image is required'),

  advertisement_title: Yup.string()
    .required('Advertisement title is required'),

  status: Yup.string()
    .required('Advertisement status is required'),
});

const AdvertisementEditModalForm: FC<Props> = ({ advertisement, isAdvertisementLoading }) => {
  const intl = useIntl()
  // Function to handle image selection
  const [selectedImage, setSelectedImage] = useState<string | null>(null);


  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [advertisementForEdit] = useState<Advertisement>({
    ...advertisement,
    advertisement_title: advertisement.advertisement_title || initialAdvertisement.advertisement_title,
    ad_slider_image_url: advertisement.ad_slider_image_url || initialAdvertisement.ad_slider_image_url,
    status: advertisement?.status || initialAdvertisement?.status,

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: advertisementForEdit,
    validationSchema: editAdvertisementSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateAdvertisement(values)
          return;
        } else {
          await createAdvertisement(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  // Function to handle image selection
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
      formik.setFieldValue('ad_slider_image_url', file);
    }
  };

  return (
    <>
      <form id='kt_modal_add_advertisement_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_advertisement_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_advertisement_header'
          data-kt-scroll-wrappers='#kt_modal_add_advertisement_scroll'
          data-kt-scroll-offset='300px'
        >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>
              <FormattedMessage id='RESELLER.FORM.INPUT.PROFILE' />
            </label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${selectedImage}')` }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${selectedImage?selectedImage:advertisement.ad_slider_image_url}')` }}
              ></div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CHANGEPROFILE' })}
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input type='file' 
                // {...formik.getFieldProps('ad_slider_image_url')}
                  name='ad_slider_image_url' onChange={(event)=>handleImageChange(event)} accept='.png, .jpg, .jpeg' />
                <input type='hidden' name='profile_image_url_remove' />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CANCELPROFILE' })}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                onClick={(e) => setSelectedImage('')}
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.REMOVEPROFILE' })}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'></div>
            {/* end::Hint */}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='ADVERTISEMENT.FORM.INPUT.ADVERTISEMENTTITLE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'ADVERTISEMENT.FORM.PLACEHOLDER.ADVERTISEMENTTITLE' })}
              {...formik.getFieldProps('advertisement_title')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.advertisement_title && formik.errors.advertisement_title },
                {
                  'is-valid': formik.touched.advertisement_title && !formik.errors.advertisement_title,
                }
              )}
              type='text'
              name='advertisement_title'
              autoComplete='off'
              disabled={formik.isSubmitting || isAdvertisementLoading}
            />
            {/* end::Input */}
            {formik.touched.advertisement_title && formik.errors.advertisement_title && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.advertisement_title}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

{/* begin::Input group */}

<div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='ADVERTISEMENT.FORM.INPUT.ADVERTISEMENTSTATUS' />
          </label>
            <select
            defaultValue={advertisement.status}
              {...formik.getFieldProps('status')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.status && formik.errors.status },
                {
                  'is-valid': formik.touched.status && !formik.errors.status,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAdvertisementLoading}

            ><option disabled selected >{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              <option value="1">Active</option>
              <option value="0">In-Active</option>
            </select>
            {formik.touched.status && formik.errors.status && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_code}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-advertisements-modal-action='cancel'
            disabled={formik.isSubmitting || isAdvertisementLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-advertisements-modal-action='submit'
            disabled={isAdvertisementLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isAdvertisementLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isAdvertisementLoading) && <AdvertisementsListLoading />}
    </>
  )
}

export { AdvertisementEditModalForm }
