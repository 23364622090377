import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'
import { Reseller, initialReseller } from '../resellers-list/core/_models'
import { useListView } from '../resellers-list/core/ListViewProvider'
import { useQueryResponse } from '../resellers-list/core/QueryResponseProvider'
import { Country, Province } from '../../country/countries-list/core/_models'
import { District } from '../../district/districts-list/core/_models'
import { isNotEmpty } from '../../../../_metronic/helpers'
import { updateReseller } from '../resellers-list/core/_requests'

const editResellerSchema = Yup.object().shape({
    reseller_name: Yup.string()
      .required('Reseller name is required'),
    
    contact_name: Yup.string()
      .required('Contact name is required'),
  
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  
      phone: Yup.string()
      .required('Phone number is required'),
  
    currency_preference_id: Yup.number()
      .required('Currency preference is required'),
  
    country_id: Yup.number()
      .required('Country is required'),
  
    province_id: Yup.number(),
  
    districts_id: Yup.number(),
  });

type Props = {
    isResellerLoading: boolean
    reseller: Reseller | any
}
const ResellerProfileDetails: React.FC<Props> = ({ reseller, isResellerLoading }) => {
    const API_URL = process.env.REACT_APP_THEME_API_URL;
    const COUNTRY_URL = `${API_URL}/admin/countries`;
    const PROVINCE_URL = `${API_URL}/admin/provinces`;
    const DISTRICTS_URL = `${API_URL}/admin/districts`;
    const intl = useIntl()
    // Function to handle image selection
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const { setItemIdForUpdate } = useListView()
    const { refetch } = useQueryResponse()
    const [countries, setCountries] = useState<[Country]>();
    const [provinces, setProvinces] = useState<[Province]>();
    const [districts, setDistricts] = useState<[District]>();
    useEffect(() => {
        // Make the API request using Axios
        //get countries
        axios
            .get(COUNTRY_URL)
            .then((response) => {
                const data = response.data;
                if (data.success) {
                    setCountries(data.data.countries);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        //get provinces
        axios
            .get(PROVINCE_URL)
            .then((response) => {
                const data = response.data;
                if (data.success) {
                    setProvinces(data.data.provinces);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        //get districts
        axios
            .get(DISTRICTS_URL)
            .then((response) => {
                const data = response.data;
                if (data.success) {
                    setDistricts(data.data.districts);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    const [resellerForEdit] = useState<Reseller>({
        ...reseller,
        profile_image_url: reseller?.profile_image_url || initialReseller.profile_image_url,
        reseller_name: reseller?.reseller_name || initialReseller.reseller_name,
        contact_name: reseller?.contact_name || initialReseller.contact_name,
        email: reseller?.email || initialReseller.email,
        phone: reseller?.phone || initialReseller.phone,
        balance: reseller?.balance || initialReseller.balance,
        country_id: reseller?.country_id || initialReseller.country_id,
        province_id: reseller?.province_id || initialReseller.province_id,
        districts_id: reseller?.districts_id || initialReseller.districts_id,
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: resellerForEdit,
        validationSchema: editResellerSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await updateReseller(values)
                }
            } catch (ex) {
                console.error(ex)
            } finally {
                setSubmitting(true)
                cancel(true)
            }
        },
    })
    // Function to handle image selection
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imageURL = URL.createObjectURL(file);
            setSelectedImage(imageURL);
            formik.setFieldValue('profile_image_url', file);
        }
    };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Profile Details</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
          {/* begin::Input group */}
          <div className='row mb-6'>
              <label className='d-block fw-bold fs-6 mb-5 col-lg-4'>
                <FormattedMessage id='RESELLER.FORM.INPUT.PROFILE' />
              </label>

            {/* begin::Input group */}
            <div className='fv-row mb-7 col-lg-8'>


              {/* begin::Image input */}
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{ backgroundImage: `url('${selectedImage?selectedImage:reseller?.profile_image_url}')` }}              >
                {/* begin::Preview existing avatar */}
                <div
                  className='image-input-wrapper w-125px h-125px'
                  style={{ backgroundImage: `url('${selectedImage?selectedImage:reseller?.profile_image_url}')` }}                 ></div>
                {/* end::Preview existing avatar */}

                {/* begin::Label */}
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CHANGEPROFILE' })}
                >
                  <i className='bi bi-pencil-fill fs-7'></i>

                  <input type='file' 
                    name='profile_image_url' onChange={(event)=>handleImageChange(event)} accept='.png, .jpg, .jpeg' />
                  <input type='hidden' name='profile_image_url_remove' />
                </label>
                {/* end::Label */}

                {/* begin::Cancel */}
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CANCELPROFILE' })}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
                {/* end::Cancel */}

                {/* begin::Remove */}
                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='remove'
                  onClick={(e) => setSelectedImage('')}
                  data-bs-toggle='tooltip'
                  title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.REMOVEPROFILE' })}
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
                {/* end::Remove */}
              </div>
              {/* end::Image input */}

              {/* begin::Hint */}
              <div className='form-text'></div>
              {/* end::Hint */}
            </div>
            {/* end::Input group */}

            {/* begin::Hint */}
            <div className='form-text'></div>
            {/* end::Hint */}
          </div>
          {/* end::Input group */}

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>              <FormattedMessage id='RESELLER.FORM.INPUT.RESELLERNAME' /></label>

                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder={intl.formatMessage({ id: 'RESELLER.FORM.PLACEHOLDER.RESELLERNAME' })}
                                            {...formik.getFieldProps('reseller_name')}
                                        />
                                        {formik.touched.reseller_name && formik.errors.reseller_name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.reseller_name}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Last name'
                                            {...formik.getFieldProps('contact_name')}
                                        />
                                        {formik.touched.contact_name && formik.errors.contact_name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.contact_name}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>              <FormattedMessage id='RESELLER.FORM.INPUT.CONTACTNAME' /></label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder={intl.formatMessage({ id: 'RESELLER.FORM.PLACEHOLDER.CONTACTNAME' })}
                                    {...formik.getFieldProps('contact_name')}
                                />
                                {formik.touched.contact_name && formik.errors.contact_name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.contact_name}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'><FormattedMessage id='RESELLER.FORM.INPUT.EMAIL' /></label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder={intl.formatMessage({ id: 'RESELLER.FORM.PLACEHOLDER.EMAIL' })}
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'><FormattedMessage id='RESELLER.FORM.INPUT.PHONE' /></span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder={`${intl.formatMessage({ id: 'RESELLER.FORM.PLACEHOLDER.PHONE' })} +93xxxxxxxxx`}
                                    {...formik.getFieldProps('phone')}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.phone}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'><FormattedMessage id='RESELLER.FORM.INPUT.COUNTRY' /></span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('country_id')}
                                >

                                    <option value='' selected>-- Select --</option>
                                    {countries && countries.map(country => (
                                        <option value={country.id}>{country.country_name}</option>
                                    ))}
                                </select>
                                {formik.touched.country_id && formik.errors.country_id && (
                                    <div className='fv-plugins-message-container'>
                                        {/* <div className='fv-help-block'>{formik.errors.country_id}</div> */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'><FormattedMessage id='RESELLER.FORM.INPUT.PROVINCE' /></span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('province_id')}
                                >

                                    <option value='' selected>-- Select --</option>
                                    {provinces && provinces.map(province => (
                                        <option value={province.id}>{province.province_name}</option>
                                    ))}
                                </select>
                                {formik.touched.province_id && formik.errors.province_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            {/* {formik.errors.province_id} */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'><FormattedMessage id='RESELLER.FORM.INPUT.DISTRICT' /></span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('districts_id')}
                                >

                                    <option value='' selected>-- Select --</option>
                                    {districts && districts.map(district => (
                                        <option value={district.id}>{district.district_name}</option>
                                    ))}
                                </select>
                                {formik.touched.districts_id && formik.errors.districts_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            {/* {formik.errors.districts_id} */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={isResellerLoading}>
                            {!isResellerLoading && 'Save Changes'}
                            {isResellerLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export { ResellerProfileDetails }
