import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialDistrict, District, Province, Country } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { DistrictsListLoading } from '../components/loading/DistrictsListLoading'
import { createDistrict, updateDistrict } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'

type Props = {
  isDistrictLoading: boolean
  district: District
}

const editDistrictSchema = Yup.object().shape({
  profile_image_url: Yup.mixed(),
})

const DistrictEditModalForm: FC<Props> = ({ district, isDistrictLoading }) => {
  const intl = useIntl()
   // Function to handle image selection
   const API_URL = process.env.REACT_APP_THEME_API_URL;
   const PROVINCE_URL = `${API_URL}/admin/provinces`;
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [provinces, setProvinces] = useState<[Province]>();
  useEffect(() => {
    // Make the API request using Axios
    axios
      .get(PROVINCE_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setProvinces(data.data.provinces);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const [districtForEdit] = useState<District>({
    ...district,
    district_name: district.district_name || initialDistrict.district_name,
    province : district?.province?.id  || initialDistrict?.province?.id ,

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: districtForEdit,
    validationSchema: editDistrictSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values",values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateDistrict(values)
        } else {
          await createDistrict(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_district_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_district_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_district_header'
          data-kt-scroll-wrappers='#kt_modal_add_district_scroll'
          data-kt-scroll-offset='300px'
        >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='DISTRICT.FORM.INPUT.DISTRICTNAME' />
</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({id: 'DISTRICT.FORM.PLACEHOLDER.DISTRICTNAME'})}
              {...formik.getFieldProps('district_name')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.district_name  && formik.errors.district_name  },
                {
                  'is-valid': formik.touched.district_name  && !formik.errors.district_name ,
                }
              )}
              type='text'
              name='district_name'
              autoComplete='off'
              disabled={formik.isSubmitting || isDistrictLoading}
            />
            {/* end::Input */}
            {formik.touched.district_name  && formik.errors.district_name  && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_id}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">              
          <FormattedMessage id='DISTRICT.FORM.INPUT.PROVINCE' />
</label>
            <select
              {...formik.getFieldProps('province_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.province && formik.errors.province },
                {
                  'is-valid': formik.touched.province && !formik.errors.province,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isDistrictLoading}

            ><option value='' defaultValue={""}>{intl.formatMessage({id: 'FORM.GENERAL.SELECT'})}</option>
              {provinces && provinces.map(province => (
                <option value={province.id}>{province.province_name}</option>
              ))}
            </select>
            {formik.touched.province && formik.errors.province && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.district_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-districts-modal-action='cancel'
            disabled={formik.isSubmitting || isDistrictLoading}
          >
          <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-districts-modal-action='submit'
            disabled={isDistrictLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isDistrictLoading) && (
              <span className='indicator-progress'>
              <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isDistrictLoading) && <DistrictsListLoading />}
    </>
  )
}

export { DistrictEditModalForm }
