import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ResellersListWrapper } from './resellers-list/ResellersList'
import AccountPage from '../../modules/accounts/AccountPage'
import { AccountHeader } from '../../modules/accounts/AccountHeader'
import { Settings } from '../../modules/accounts/components/settings/Settings'
import { Overview } from '../../modules/accounts/components/Overview'
import { ResellerViewPage } from './component/ResellerViewPage'

const resellersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Reseller Management',
    path: '/admin/pages/resellers',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ResellersPage = () => {
  return (
    <Routes>
      <Route >
        <Route
          // path='resellers'
          index
          element={
            <>
              <PageTitle breadcrumbs={resellersBreadcrumbs}>Resellers list</PageTitle>
              <ResellersListWrapper />
            </>
          }
        />

        <Route path='/overview/:id/*' element={<ResellerViewPage />}>
          
        </Route>

      </Route>
      <Route index element={<Navigate to='/admin/pages/resellers' />} />
    </Routes>
  )
}

export default ResellersPage
