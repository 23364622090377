// @ts-nocheck
import {Column} from 'react-table'
import {ServiceCategorySubTypeInfoCell} from './ServiceCategorySubTypeInfoCell'
import {ServiceCategorySubTypeLastLoginCell} from './ServiceCategorySubTypeLastLoginCell'
import {ServiceCategorySubTypeTwoStepsCell} from './ServiceCategorySubTypeTwoStepsCell'
import {ServiceCategorySubTypeActionsCell} from './ServiceCategorySubTypeActionsCell'
import {ServiceCategorySubTypeSelectionCell} from './ServiceCategorySubTypeSelectionCell'
import {ServiceCategorySubTypeCustomHeader} from './ServiceCategorySubTypeCustomHeader'
import {ServiceCategorySubTypeSelectionHeader} from './ServiceCategorySubTypeSelectionHeader'
import {ServiceCategorySubType} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const servicecategorysubtypesColumns: ReadonlyArray<Column<ServiceCategorySubType>> = [
  {
    Header: (props) => <ServiceCategorySubTypeSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ServiceCategorySubTypeSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ServiceCategorySubTypeCustomHeader tableProps={props} title={<FormattedMessage id='SERVICECATEGORYSUBTYPE.TABLE.COLUMN.SERVICECATEGORYSUBTYPENAME' />} className='min-w-125px' />,
    id: 'sub_type_name',
    accessor:'sub_type_name'
  },
  {
    Header: (props) => <ServiceCategorySubTypeCustomHeader tableProps={props} title={<FormattedMessage id='SERVICECATEGORYSUBTYPE.DESCRIPTIONS' />} className='min-w-125px' />,
    accessor: 'description', // Access the country name from the nested structure.
    Cell: ({ value }) => value, // Display the country name.
  },

  {
    Header: (props) => (
      <ServiceCategorySubTypeCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ServiceCategorySubTypeActionsCell id={props.data[props.row.index].id} />,
  },
]

export {servicecategorysubtypesColumns}
