import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTSVG } from '../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL;
const CURRENCY_URL = `${API_URL}/admin/currencies`;

const ResellersListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [resellerType, setResellerType] = useState<string | undefined>()
  const [resellerStatus, setResellerStatus] = useState<string | undefined>()
  const [balanceInquiryType, setBalanceInquiryType] = useState<string | undefined>()
  const [amount, setAmount] = useState<string | undefined>()
  const [currencies, setCurrencies] = useState<{ id: string, name: string, symbol: string }[]>([])
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<string | undefined>()
  const [currencySymbol, setCurrencySymbol] = useState<string>('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    axios
      .get(CURRENCY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCurrencies(data.data.currencies);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  
  useEffect(() => {
    if (selectedCurrencyId) {
      const selectedCurrency = currencies.find(currency => currency.id === selectedCurrencyId);
      if (selectedCurrency) {
        setCurrencySymbol(selectedCurrency.symbol);
      }
    }
  }, [selectedCurrencyId, currencies]);

  const resetData = () => {
    setResellerType(undefined)
    setResellerStatus(undefined)
    setBalanceInquiryType(undefined)
    setAmount(undefined)
    setSelectedCurrencyId(undefined)
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: {
        reseller_type: resellerType,
        reseller_status: resellerStatus,
        balance_inquiry_type: balanceInquiryType,
        amount: amount,
        currency_id: selectedCurrencyId,
      },
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.FILTER' />
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>
            <FormattedMessage id='TABLE.GENERAL.FILTEROPTION' />
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-reseller-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Reseller Type:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-reseller-table-filter='reseller_type'
              data-hide-search='true'
              onChange={(e) => setResellerType(e.target.value)}
              value={resellerType}
            >
              <option value=''>All</option>
              <option value='resellers'>Main Resellers</option>
              <option value='subresellers'>Sub-Resellers</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Reseller Status:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-reseller-table-filter='reseller_status'
              data-hide-search='true'
              onChange={(e) => setResellerStatus(e.target.value)}
              value={resellerStatus}
            >
              <option value=''></option>
              <option value='1'>Active</option>
              <option value='0'>Inactive</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Balance Inquiry Type:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-reseller-table-filter='balance_inquiry_type'
              data-hide-search='true'
              onChange={(e) => setBalanceInquiryType(e.target.value)}
              value={balanceInquiryType}
            >
              <option value=''></option>
              <option value='gt'>Greater Than</option>
              <option value='ls'>Less Than</option>
              <option value='eq'>Equal</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Amount:</label>
            <div className='input-group'>
              <input
                type='number'
                className='form-control form-control-solid'
                placeholder='Enter amount'
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
              {currencySymbol && <span className='input-group-text'>{currencySymbol}</span>}
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Currency:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-reseller-table-filter='currency_id'
              data-hide-search='true'
              onChange={(e) => setSelectedCurrencyId(e.target.value)}
              value={selectedCurrencyId}
            >
              <option value=''></option>
              {currencies.map(currency => (
                <option key={currency.id} value={currency.id}>
                  {currency.name} - {currency.symbol}
                </option>
              ))}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-reseller-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-reseller-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { ResellersListFilter }
