import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { AdvertisementsListWrapper } from './advertisements-list/AdvertisementsList'
import AccountPage from '../../modules/accounts/AccountPage'
import { AccountHeader } from '../../modules/accounts/AccountHeader'
import { Settings } from '../../modules/accounts/components/settings/Settings'
import { Overview } from '../../modules/accounts/components/Overview'

const advertisementsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Advertisement Management',
    path: '/admin/pages/advertisements',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/admin/pages/advertisements/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const AdvertisementsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          // path='advertisements'
          index
          element={
            <>
              <PageTitle breadcrumbs={advertisementsBreadcrumbs}>Advertisements list</PageTitle>
              <AdvertisementsListWrapper />
            </>
          }
        />

        <Route
          element={
            <>
              <AccountHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='overview'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
                <Overview />
              </>
            }
          />
          <Route
            path='settings'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
                <Settings />
              </>
            }
          />
          <Route index element={<Navigate to='/admin/pages/advertisements/overview' />} />
        </Route>

      </Route>
      <Route index element={<Navigate to='/admin/pages/advertisements' />} />
    </Routes>
  )
}

export default AdvertisementsPage
