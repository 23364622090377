import axios, {AxiosResponse} from 'axios'
import {ID, AfghanNetResponse, Response} from '../../../../../_metronic/helpers'
import {District, DistrictsQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DISTRICT_URL = `${API_URL}/admin/districts`
const GET_DISTRICTS_URL = `${API_URL}/admin/districts`

const getDistricts = (query: string): Promise<DistrictsQueryResponse | AfghanNetResponse<District[]>> => {
  return axios
    .get(`${GET_DISTRICTS_URL}?${query}`)
    .then((d: AxiosResponse<AfghanNetResponse<District[]>>) => {
      return d.data
    })
}

const getDistrictById = async (id: ID): Promise<District> => {
  try {
    const {data} = await axios.get(`${DISTRICT_URL}/${id}`)
    return data?.data?.district
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${DISTRICT_URL}/${id}`).then((response: AxiosResponse<District>) => response.data)
}
const createDistrict = (district: District): Promise<District | undefined> => {
  return axios
    .post(DISTRICT_URL, district)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<District>) => response.data)
  }

const updateDistrict = (district: District): Promise<District | undefined> => {
  return axios
    .put(`${DISTRICT_URL}/${district.id}`, district)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<District>) => response.data)
  }

const deleteDistrict = (districtId: ID): Promise<void> => {
  return axios.delete(`${DISTRICT_URL}/${districtId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedDistricts = (districtIds: Array<ID>): Promise<void> => {
  const requests = districtIds.map((id) => axios.delete(`${DISTRICT_URL}/${id}`))
  return axios.all(requests).then(() => {
    toast.success("Action completed...",{position:'top-right'})
  })
}

export {
  getDistricts,
  deleteDistrict,
  deleteSelectedDistricts,
  getDistrictById,
  createDistrict,
  updateDistrict,
}
