import {useQuery} from 'react-query'
import {CountryEditModalForm} from './CountryEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getCountryById} from '../core/_requests'

const CountryEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: country,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-country-${itemIdForUpdate}`,
    () => {
      return getCountryById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <CountryEditModalForm isCountryLoading={isLoading} country={{id: undefined}} />
  }

  if (!isLoading && !error && country) {
    console.log({country, },"its country")
    console.log(typeof country,"its country")
    return <CountryEditModalForm isCountryLoading={isLoading} country={country} />
  }

  return null
}

export {CountryEditModalFormWrapper}
