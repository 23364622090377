import { FormattedMessage } from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CountriesListFilter} from './CountriesListFilter'

const CountriesListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddCountryModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-country-table-toolbar='base'>
      <CountriesListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.EXPORT' />      </button>
      {/* end::Export */}

      {/* begin::Add country */}
      <button type='button' className='btn btn-primary' onClick={openAddCountryModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id='COUNTRY.TABLE.CREATECOUNTRY' />
      </button>
      {/* end::Add country */}
    </div>
  )
}

export {CountriesListToolbar}
