import { FormattedMessage } from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CompanyCodesListFilter} from './CompanyCodesListFilter'

const CompanyCodesListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddCompanyCodeModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-companycode-table-toolbar='base'>
      <CompanyCodesListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.EXPORT' />      </button>
      {/* end::Export */}

      {/* begin::Add companycode */}
      <button type='button' className='btn btn-primary' onClick={openAddCompanyCodeModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id='COMPANYCODE.TABLE.CREATECOMPANYCODE' />
      </button>
      {/* end::Add companycode */}
    </div>
  )
}

export {CompanyCodesListToolbar}
