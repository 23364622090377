// @ts-nocheck
import {Column} from 'react-table'
import {LanguageInfoCell} from './LanguageInfoCell'
import {LanguageLastLoginCell} from './LanguageLastLoginCell'
import {LanguageTwoStepsCell} from './LanguageTwoStepsCell'
import {LanguageActionsCell} from './LanguageActionsCell'
import {LanguageSelectionCell} from './LanguageSelectionCell'
import {LanguageCustomHeader} from './LanguageCustomHeader'
import {LanguageSelectionHeader} from './LanguageSelectionHeader'
import {Language} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const languagesColumns: ReadonlyArray<Column<Language>> = [
  {
    Header: (props) => <LanguageSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <LanguageSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <LanguageCustomHeader tableProps={props} title={<FormattedMessage id='LANGUAGE.TABLE.COLUMN.LANGUAGENAME' />} className='min-w-125px' />,
    id: 'language_name',
    accessor:'language_name'
  },
  {
    Header: (props) => <LanguageCustomHeader tableProps={props} title={<FormattedMessage id='LANGUAGE.TABLE.COLUMN.LANGUAGECODE' />} className='min-w-125px' />,
    accessor: 'language_code', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  {
    Header: (props) => <LanguageCustomHeader tableProps={props} title={<FormattedMessage id='LANGUAGE.TABLE.COLUMN.DIRECTION' />} className='min-w-125px' />,
    accessor: 'direction', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  // {
  //   Header: (props) => <LanguageCustomHeader tableProps={props} title={<FormattedMessage id='LANGUAGE.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <LanguageCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <LanguageActionsCell id={props.data[props.row.index].id} />,
  },
]

export {languagesColumns}
