import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { ResellerView } from './ResellerView'
import { ResellerSettings } from './ResellerSettings'
import { ResellerAccountHeader } from './ResellerAccountHeader'
import { getResellerById, getSubResellersByResellerId } from '../resellers-list/core/_requests'
import { QUERIES, isNotEmpty } from '../../../../_metronic/helpers'
import { useQuery } from 'react-query'

const ResellerViewPage = () => {
    const params = useParams<{ id: string }>()
    const enabledQuery: boolean = isNotEmpty(params.id)

    const {
        isLoading,
        data: reseller,
        error,
    } = useQuery(
        `${QUERIES.RESELLERS_LIST}-reseller-${params.id}`,
        () => getResellerById(params.id),
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                console.error(err)
            },
        }
    )

    // const {
    //     data: subResellers,
    //     isLoading: isSubResellersLoading,
    //     error: subResellersError,
    // } = useQuery(
    //     `${QUERIES.RESELLERS_LIST}-sub-resellers-${params.id}`,
    //     () => getSubResellersByResellerId(params.id),
    //     {
    //         enabled: !!params.id, // Ensure the query is only enabled if `params.id` is available
    //         onError: (err) => {
    //             console.error(err)
    //         },
    //     }
    // )
    // useEffect(()=>{
    //     console.log(subResellers,'suv')
    // },[]);
    const accountBreadCrumbs: Array<PageLink> = [
        {
            title: 'Account',
            path: '/admin/pages/resellers/overview',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    // if (isLoading || isSubResellersLoading) {
    //     return <div>Loading...</div>
    // }

    return (
        <Routes>
            <Route
                path='*'
                element={
                    <>
                        <ResellerAccountHeader isResellerLoading={isLoading} reseller={reseller} />
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='view'
                    element={
                        <>
                            <ResellerView
                                isResellerLoading={isLoading}
                                reseller={reseller}
                                subResellers={[] || []} // Pass sub-resellers data
                            />
                        </>
                    }
                />
                <Route
                    path='settings'
                    element={
                        <>
                            <ResellerSettings isResellerLoading={isLoading} reseller={reseller} />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export { ResellerViewPage }
