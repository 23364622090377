import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ProvincesListHeader} from './components/header/ProvincesListHeader'
import {ProvincesTable} from './table/ProvincesTable'
import {ProvinceEditModal} from './province-edit-modal/ProvinceEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const ProvincesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ProvincesListHeader />
        <ProvincesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ProvinceEditModal />}
    </>
  )
}

const ProvincesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProvincesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProvincesListWrapper}
