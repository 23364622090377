import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {OrdersListHeader} from './components/header/OrdersListHeader'
import {OrdersTable} from './table/OrdersTable'
import {OrderEditModal} from './order-edit-modal/OrderEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const OrdersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <OrdersListHeader />
        <OrdersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <OrderEditModal />}
    </>
  )
}

const OrdersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OrdersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {OrdersListWrapper}
