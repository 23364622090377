import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Company, initialCompanyCode, CompanyCode } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { CompanyCodesListLoading } from '../components/loading/CompanyCodesListLoading'
import { createCompanyCode, updateCompanyCode } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'

type Props = {
  isCompanyCodeLoading: boolean
  companycode: CompanyCode
}

const editCompanyCodeSchema = Yup.object().shape({
  profile_image_url: Yup.mixed(),
})

const CompanyCodeEditModalForm: FC<Props> = ({ companycode, isCompanyCodeLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const COMPANY_URL = `${API_URL}/admin/companies`;
  const COMPANYCODE_CATEGORY_URL = `${API_URL}/admin/companies`;
  const intl = useIntl()
  // Function to handle image selection
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  // Function to handle image selection
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);

    }
  };

  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [companies, setCompanies] = useState<[Company]>();
  const [company, setCompany] = useState<[Company]>();
  useEffect(() => {
    // Make the API request using Axios
    //get companies
    axios
      .get(COMPANY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCompanies(data.data.companies);
        }
      })
      .catch((error) => {
        console.error(error);
      });
      //get provinces
      axios
      .get(COMPANYCODE_CATEGORY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCompany(data.data.companies);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const [companycodeForEdit] = useState<CompanyCode>({
    ...companycode,
    company_Id: companycode.company_Id || initialCompanyCode.company_Id,
    reserved_digit: companycode.reserved_digit || initialCompanyCode.reserved_digit,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: companycodeForEdit,
    validationSchema: editCompanyCodeSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values", values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateCompanyCode(values)
        } else {
          await createCompanyCode(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_companycode_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_companycode_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_companycode_header'
          data-kt-scroll-wrappers='#kt_modal_add_companycode_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='COMPANYCODE.FORM.INPUT.RESERVEDDIGIT' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'COMPANYCODE.FORM.PLACEHOLDER.RESERVEDDIGIT' })}
              {...formik.getFieldProps('reserved_digit')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.reserved_digit && formik.errors.reserved_digit },
                {
                  'is-valid': formik.touched.reserved_digit && !formik.errors.reserved_digit,
                }
              )}
              type='text'
              name='reserved_digit'
              autoComplete='off'
              disabled={formik.isSubmitting || isCompanyCodeLoading}
            />
            {/* end::Input */}
            {formik.touched.reserved_digit && formik.errors.reserved_digit && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.company}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='COMPANYCODE.FORM.INPUT.COMPANYNAME' />
          </label>
            <select
              {...formik.getFieldProps('company_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.company && formik.errors.company },
                {
                  'is-valid': formik.touched.company && !formik.errors.company,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCompanyCodeLoading}

            ><option value='' defaultValue={""}>{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              {company && company.map(company => (
                <option value={company.id}>{company.company_name}</option>
              ))}
            </select>
            {formik.touched.company && formik.errors.company && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.companycode_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-companycodes-modal-action='cancel'
            disabled={formik.isSubmitting || isCompanyCodeLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-companycodes-modal-action='submit'
            disabled={isCompanyCodeLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCompanyCodeLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCompanyCodeLoading) && <CompanyCodesListLoading />}
    </>
  )
}

export { CompanyCodeEditModalForm }
