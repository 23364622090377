// @ts-nocheck
import {Column} from 'react-table'
import {AdvertisementInfoCell} from './AdvertisementInfoCell'
import {AdvertisementLastLoginCell} from './AdvertisementLastLoginCell'
import {AdvertisementTwoStepsCell} from './AdvertisementTwoStepsCell'
import {AdvertisementActionsCell} from './AdvertisementActionsCell'
import {AdvertisementSelectionCell} from './AdvertisementSelectionCell'
import {AdvertisementCustomHeader} from './AdvertisementCustomHeader'
import {AdvertisementSelectionHeader} from './AdvertisementSelectionHeader'
import {Advertisement} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const advertisementsColumns: ReadonlyArray<Column<Advertisement>> = [
  {
    Header: (props) => <AdvertisementSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <AdvertisementSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <AdvertisementCustomHeader tableProps={props} title={<FormattedMessage id='ADVERTISEMENT.TABLE.COLUMN.ADVERTISEMENTIMAGE' />} className='min-w-125px' />,
    id: 'ad_slider_image_url',
    Cell: ({...props}) => <AdvertisementInfoCell advertisement={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <AdvertisementCustomHeader tableProps={props} title={<FormattedMessage id='ADVERTISEMENT.TABLE.COLUMN.ADVERTISEMENTTITLE' />} className='min-w-125px' />,
    id: 'advertisement_title',
    accessor:'advertisement_title'
  },
  {
    Header: (props) => <AdvertisementCustomHeader tableProps={props} title={<FormattedMessage id='ADVERTISEMENT.TABLE.COLUMN.ADVERTISEMENTSTATUS' />} className='min-w-125px' />,
    id: 'status',
    accessor:'status',
    Cell: ({ value }) => (value === 0 ? 'Deactive' : 'Active'),
  },
  // {
  //   Header: (props) => <AdvertisementCustomHeader tableProps={props} title={<FormattedMessage id='ADVERTISEMENT.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <AdvertisementCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <AdvertisementActionsCell id={props.data[props.row.index].id} />,
  },
]

export {advertisementsColumns}
