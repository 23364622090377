import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialCurrency, Currency } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { CurrenciesListLoading } from '../components/loading/CurrenciesListLoading'
import { createCurrency, updateCurrency } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'

type Props = {
  isCurrencyLoading: boolean
  currency: Currency
}

const editCurrencySchema = Yup.object().shape({
  profile_image_url: Yup.mixed(),
})

const CurrencyEditModalForm: FC<Props> = ({ currency, isCurrencyLoading }) => {
  const intl = useIntl()
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [currencies, setCurrencies] = useState<[Currency]>();
  const [currencyForEdit] = useState<Currency>({
    ...currency,
    name: currency.name || initialCurrency.name,
    code: currency.code || initialCurrency.code,
    symbol: currency.symbol || initialCurrency.symbol,
    exchange_rate_per_usd: currency.exchange_rate_per_usd || initialCurrency.exchange_rate_per_usd,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: currencyForEdit,
    validationSchema: editCurrencySchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values", values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateCurrency(values)
        } else {
          await createCurrency(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
    <div className="hint">
      <div className='hint'><FormattedMessage id='CURRENCY.GENERAL.HINT' /></div>
AFN, IRR, INR, PKR: ؋, ﷼, ₹, Rs
USD: $
EUR: €
JPY: ¥
GBP: £
AUD, CAD: $
CHF: Fr
CNY: ¥
SEK: kr
NZD: $
MXN: $
SGD, HKD: $
NOK: kr
KRW: ₩
TRY: ₺
BRL: R$
ZAR: R
    </div>
    <br />
      <form id='kt_modal_add_currency_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_currency_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_currency_header'
          data-kt-scroll-wrappers='#kt_modal_add_currency_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='CURRENCY.FORM.INPUT.CURRENCYNAME' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'CURRENCY.FORM.PLACEHOLDER.CURRENCYNAME' })}
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              type='text'
              name='name'
              autoComplete='off'
              disabled={formik.isSubmitting || isCurrencyLoading}
            />
            {/* end::Input */}
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='CURRENCY.FORM.INPUT.CURRENCYCODE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'CURRENCY.FORM.PLACEHOLDER.CURRENCYCODE' })}
              {...formik.getFieldProps('code')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.code && formik.errors.code },
                {
                  'is-valid': formik.touched.code && !formik.errors.code,
                }
              )}
              type='text'
              name='code'
              autoComplete='off'
              disabled={formik.isSubmitting || isCurrencyLoading}
            />
            {/* end::Input */}
            {formik.touched.code && formik.errors.code && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='CURRENCY.FORM.INPUT.SYMBOL' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'CURRENCY.FORM.PLACEHOLDER.SYMBOL' })}
              {...formik.getFieldProps('symbol')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.symbol && formik.errors.symbol },
                {
                  'is-valid': formik.touched.symbol && !formik.errors.symbol,
                }
              )}
              type='text'
              name='symbol'
              autoComplete='off'
              disabled={formik.isSubmitting || isCurrencyLoading}
            />
            {/* end::Input */}
            {formik.touched.symbol && formik.errors.symbol && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='CURRENCY.FORM.INPUT.EXCHANGERATE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'CURRENCY.FORM.PLACEHOLDER.EXCHANGERATE' })}
              {...formik.getFieldProps('exchange_rate_per_usd')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.exchange_rate_per_usd && formik.errors.exchange_rate_per_usd },
                {
                  'is-valid': formik.touched.exchange_rate_per_usd && !formik.errors.exchange_rate_per_usd,
                }
              )}
              type='text'
              name='exchange_rate_per_usd'
              autoComplete='off'
              disabled={formik.isSubmitting || isCurrencyLoading}
            />
            {/* end::Input */}
            {formik.touched.exchange_rate_per_usd && formik.errors.exchange_rate_per_usd && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-currencies-modal-action='cancel'
            disabled={formik.isSubmitting || isCurrencyLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-currencies-modal-action='submit'
            disabled={isCurrencyLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCurrencyLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCurrencyLoading) && <CurrenciesListLoading />}
    </>
  )
}

export { CurrencyEditModalForm }
