/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useLocation, useParams } from 'react-router'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../_metronic/partials'
import { Reseller } from '../resellers-list/core/_models'
import { FormattedMessage } from 'react-intl'

type Props = {
    isResellerLoading: boolean
    reseller: Reseller | any
}
const ResellerAccountHeader: React.FC<Props> = ({ reseller, isResellerLoading }) => {
    const location = useLocation()
    const params = useParams();
    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img src={reseller?.profile_image_url} alt='Metronic' />
                            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                        </div>
                    </div>

                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                        {reseller?.reseller_name || 'Loading...'}
                                    </a>
                                    <a href='#'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen026.svg'
                                            className='svg-icon-1 svg-icon-primary'
                                        />
                                    </a>
                                    {/* <a
                    href='#'
                    className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_upgrade_plan'
                  >
                    Upgrade to Pro
                  </a> */}
                                </div>

                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/communication/com006.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {reseller?.contact_name ||'loading...'}
                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/communication/com006.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {reseller?.phone || 'Loading...'}
                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen018.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {`${reseller?.country?.country_name} - ${reseller?.province?.province_name} - ${reseller?.districts?.district_name}`}
                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/communication/com011.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {reseller?.email || 'Loading...'}
                                    </a>
                                </div>
                            </div>

                            {/* <div className='d-flex my-4'>
                                <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr012.svg'
                                        className='svg-icon-3 d-none'
                                    />

                                    <span className='indicator-label'>Follow</span>
                                    <span className='indicator-progress'>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                </a>
                                <a
                                    href='#'
                                    className='btn btn-sm btn-primary me-3'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_offer_a_deal'
                                >
                                    Hire Me
                                </a>
                                <div className='me-0'>
                                    <button
                                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                    >
                                        <i className='bi bi-three-dots fs-3'></i>
                                    </button>
                                    <Dropdown1 />
                                </div>
                            </div> */}
                        </div>

                        <div className='d-flex flex-wrap flex-stack'>
                            <div className='d-flex flex-column flex-grow-1 pe-8'>
                                <div className='d-flex flex-wrap'>
                                    <div className='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr066.svg'
                                                className='svg-icon-3 svg-icon-success me-2'
                                            />
                                            <div className='fs-2 fw-bolder'>{`${reseller?.balance} ${reseller?.currency.code}`}</div>
                                            
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'><FormattedMessage id='RESELLER.VIEW.BALANCE' /></div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr066.svg'
                                                className='svg-icon-3 svg-icon-success me-2'
                                            />
                                            <div className='fs-2 fw-bolder'>{`${reseller?.total_orders}`}</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'><FormattedMessage id='RESELLER.VIEW.TOTALORDER' /></div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr066.svg'
                                                className='svg-icon-3 svg-icon-success me-2'
                                            />
                                            <div className='fs-2 fw-bolder'>{`${reseller?.today_orders}`}</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'><FormattedMessage id='RESELLER.VIEW.TODAYORDER' /></div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr066.svg'
                                                className='svg-icon-3 svg-icon-success me-2'
                                            />
                                            <div className='fs-2 fw-bolder'>{`${reseller?.total_sale} ${reseller?.currency.code}`}</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'>
                                            <FormattedMessage id='RESELLER.VIEW.TOTALSALE' />
                                        </div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr066.svg'
                                                className='svg-icon-3 svg-icon-success me-2'
                                            />
                                            <div className='fs-2 fw-bolder'>{`${reseller?.total_profit} ${reseller?.currency.code}`}</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'>
                                            <FormattedMessage id='RESELLER.VIEW.TOTALPROFIT' />
                                        </div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr066.svg'
                                                className='svg-icon-3 svg-icon-success me-2'
                                            />
                                            <div className='fs-2 fw-bolder'>{`${reseller?.today_sale} ${reseller?.currency.code}`}</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'>
                                            <FormattedMessage id='RESELLER.VIEW.TODAYSALE' />
                                        </div>
                                    </div>
                                    <div className='border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr066.svg'
                                                className='svg-icon-3 svg-icon-success me-2'
                                            />
                                            <div className='fs-2 fw-bolder'>{`${reseller?.today_profit} ${reseller?.currency.code}`}</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'>
                                            <FormattedMessage id='RESELLER.VIEW.TODAYPROFIT' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                                    <span className='fw-bolder fs-6'>50%</span>
                                </div>
                                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                                    <div
                                        className='bg-success rounded h-5px'
                                        role='progressbar'
                                        style={{ width: '50%' }}
                                    ></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/admin/pages/resellers/overview/${params.id}/view` && 'active')
                                }
                                to={`view`}
                            >
                                Overview
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/admin/pages/resellers/overview/${params.id}/settings` && 'active')
                                }
                                to={`settings`}
                            >
                                Settings
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { ResellerAccountHeader }
