import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import { Service } from '../../../../service/services-list/core/_models'
import { Company } from '../../../../company/companies-list/core/_models'

const BundlesListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [service_category_type, setServiceCategoryType] = useState<string | undefined>()
  const [company_id, setCompanyId] = useState<string | undefined>()
  const [service_id, setServiceId] = useState<string | undefined>()

  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const COMPANY_URL = `${API_URL}/admin/companies`;
  const SERVICE_URL = `${API_URL}/admin/services`;
  const [companies, setCompanies] = useState<[Company]>();
  const [services, setServices] = useState<[Service]>();
  useEffect(() => {
    // Make the API request using Axios
    //get companies
    axios
      .get(COMPANY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCompanies(data.data.companies);
        }
      })
      .catch((error) => {
        console.error(error);
      });
      //get provinces
      axios
      .get(SERVICE_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setServices(data.data.services);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter: {service_category_type, company_id,service_id},
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.FILTEROPTION' />

      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'><FormattedMessage id='APP.GENERAL.TABLE.FILTER.OPTIONLIST' /></div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-bundle-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'><FormattedMessage id='BUNDLE.TABLE.FILTER.TYPE' /></label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-bundle-table-filter='service_category_type'
              data-hide-search='true'
              onChange={(e) => setServiceCategoryType(e.target.value)}
              value={service_category_type}
            >
              <option value=''>Select</option>
              <option value='social'><FormattedMessage id='BUNDLE.TABLE.FILTER.SOCIAL' /></option>
              <option value='nonsocial'><FormattedMessage id='BUNDLE.TABLE.FILTER.NONSOCIAL' /></option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'><FormattedMessage id='BUNDLE.TABLE.FILTER.COMPANY' />:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-bundle-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setCompanyId(e.target.value)}
              value={company_id}
            >
              <option value=''></option>
              {companies && companies.map(company => (
                <option value={company.id}>{company.company_name}</option>
              ))}
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'><FormattedMessage id='BUNDLE.TABLE.FILTER.SERVICE' /></label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-bundle-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setServiceId(e.target.value)}
              value={service_id}
            >
              <option value=''></option>
              {services && services.map(service => (
                <option value={service.id}>{service.service_category?.category_name}-{service.company?.company_name}</option>
              ))}
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-bundle-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              onClick={filterData}
              type='button'
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-bundle-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {BundlesListFilter}
