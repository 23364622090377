import { FormattedMessage } from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {LanguagesListFilter} from './LanguagesListFilter'

const LanguagesListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddLanguageModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-language-table-toolbar='base'>
      <LanguagesListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.EXPORT' />      </button>
      {/* end::Export */}

      {/* begin::Add language */}
      <button type='button' className='btn btn-primary' onClick={openAddLanguageModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id='LANGUAGE.TABLE.CREATELANGUAGE' />
      </button>
      {/* end::Add language */}
    </div>
  )
}

export {LanguagesListToolbar}
