import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {BundlesListHeader} from './components/header/BundlesListHeader'
import {BundlesTable} from './table/BundlesTable'
import {BundleEditModal} from './bundle-edit-modal/BundleEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const BundlesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <BundlesListHeader />
        <BundlesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <BundleEditModal />}
    </>
  )
}

const BundlesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <BundlesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BundlesListWrapper}
