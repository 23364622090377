// @ts-nocheck
import {Column} from 'react-table'
import {BundleInfoCell} from './BundleInfoCell'
import {BundleLastLoginCell} from './BundleLastLoginCell'
import {BundleTwoStepsCell} from './BundleTwoStepsCell'
import {BundleActionsCell} from './BundleActionsCell'
import {BundleSelectionCell} from './BundleSelectionCell'
import {BundleCustomHeader} from './BundleCustomHeader'
import {BundleSelectionHeader} from './BundleSelectionHeader'
import {Bundle} from '../../core/_models'
import { FormattedMessage } from 'react-intl'
import { CompanyInfoCell } from '../../../../company/companies-list/table/columns/CompanyInfoCell'

const bundlesColumns: ReadonlyArray<Column<Bundle>> = [
  {
    Header: (props) => <BundleSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <BundleSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.BUNDLENAME' />} className='min-w-125px' />,
    id: 'bundle_title',
    accessor:'bundle_title'
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.BUNDLEDESCRIPTION' />} className='min-w-125px' />,
    id: 'bundle_description',
    accessor:'bundle_description'
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.VALIDITYTYPE' />} className='min-w-125px' />,
    id: 'validity_type',
    accessor:'validity_type'
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.AMOUNT' />} className='min-w-125px' />,
    id: 'amount',
    accessor:'amount'
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.ADMINBUYINGPRICE' />} className='min-w-125px' />,
    id: 'admin_buying_price',
    accessor:'admin_buying_price'
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.BUYINGPRICE' />} className='min-w-125px' />,
    id: 'buying_price',
    accessor:'buying_price'
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.SELLINGPRICE' />} className='min-w-125px' />,
    id: 'selling_price',
    accessor:'selling_price',
    Cell: ({ value }) => `${value}`, 

  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.CURRENCYNAME' />} className='min-w-125px' />,
    accessor: 'currency.name', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, 
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.COMPANYNAME' />} className='min-w-125px' />,
    accessor: `service.company.company_name`,
    id: 'company_logo', // Access the currency name from the nested structure.
    Cell: ({...props}) => <BundleInfoCell bundle={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.SERVICECATEGORY' />} className='min-w-125px' />,
    accessor: 'service.service_category.category_name', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, 
  },
  {
    Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.CREATEDAT' />} className='min-w-125px' />,
    id: 'created_at',
    accessor:'created_at'
  },
  // {
  //   Header: (props) => <BundleCustomHeader tableProps={props} title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <BundleCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <BundleActionsCell id={props.data[props.row.index].id} />,
  },
]

export {bundlesColumns}
