import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { TransactionsListWrapper } from './transactions-list/TransactionsList'
import AccountPage from '../../modules/accounts/AccountPage'
import { AccountHeader } from '../../modules/accounts/AccountHeader'
import { Settings } from '../../modules/accounts/components/settings/Settings'
import { Overview } from '../../modules/accounts/components/Overview'

const transactionsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Transaction Management',
    path: '/admin/pages/transactions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/admin/pages/transactions/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const TransactionsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          // path='transactions'
          index
          element={
            <>
              <PageTitle breadcrumbs={transactionsBreadcrumbs}>Transactions list</PageTitle>
              <TransactionsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/admin/pages/transactions' />} />
    </Routes>
  )
}

export default TransactionsPage
