import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CurrenciesListHeader} from './components/header/CurrenciesListHeader'
import {CurrenciesTable} from './table/CurrenciesTable'
import {CurrencyEditModal} from './currency-edit-modal/CurrencyEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const CurrenciesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CurrenciesListHeader />
        <CurrenciesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CurrencyEditModal />}
    </>
  )
}

const CurrenciesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CurrenciesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CurrenciesListWrapper}
