import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { CountriesListWrapper } from './countries-list/CountriesList'
import AccountPage from '../../modules/accounts/AccountPage'
import { AccountHeader } from '../../modules/accounts/AccountHeader'
import { Settings } from '../../modules/accounts/components/settings/Settings'
import { Overview } from '../../modules/accounts/components/Overview'

const countriesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Country Management',
    path: '/admin/pages/countries',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/admin/pages/countries/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const CountriesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          // path='countries'
          index
          element={
            <>
              <PageTitle breadcrumbs={countriesBreadcrumbs}>Countries list</PageTitle>
              <CountriesListWrapper />
            </>
          }
        />

        <Route
          element={
            <>
              <AccountHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='overview'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
                <Overview />
              </>
            }
          />
          <Route
            path='settings'
            element={
              <>
                <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
                <Settings />
              </>
            }
          />
          <Route index element={<Navigate to='/admin/pages/countries/overview' />} />
        </Route>

      </Route>
      <Route index element={<Navigate to='/admin/pages/countries' />} />
    </Routes>
  )
}

export default CountriesPage
