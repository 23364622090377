import axios, {AxiosResponse} from 'axios'
import {ID, AfghanNetResponse, Response} from '../../../../../_metronic/helpers'
import {Advertisement, AdvertisementsQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const ADVERTISEMENT_URL = `${API_URL}/admin/advertisements`
const GET_ADVERTISEMENTS_URL = `${API_URL}/admin/advertisements`

const getAdvertisements = (query: string): Promise<AdvertisementsQueryResponse | AfghanNetResponse<Advertisement[]>> => {
  return axios
    .get(`${GET_ADVERTISEMENTS_URL}?${query}`)
    .then((d: AxiosResponse<AfghanNetResponse<Advertisement[]>>) => {
      return d.data
    })
}

const getAdvertisementById = async (id: ID): Promise<Advertisement> => {
  try {
    const {data} = await axios.get(`${ADVERTISEMENT_URL}/${id}`)
    return data?.data?.advertisement
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${ADVERTISEMENT_URL}/${id}`).then((response: AxiosResponse<Advertisement>) => response.data)
}
const createAdvertisement = (advertisement: Advertisement): Promise<Advertisement | undefined> => {
  const formData = new FormData();
  Object.entries(advertisement).forEach(([key, value]) => {
    // Handle image separately
      formData.append(key, value);
  });

  return axios
    .post(ADVERTISEMENT_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Advertisement>) => response.data);
}
const updateAdvertisement = (advertisement: Advertisement): Promise<Advertisement | undefined> => {
  const newFormData = new FormData();
  Object.entries(advertisement).forEach(([key, value]) => {
    // Handle image separately
      newFormData.append(key, value);
  });
  newFormData.append('_method','put');
  return axios
    .put(`${ADVERTISEMENT_URL}/${advertisement.id}`, newFormData,{
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Advertisement>) => response.data)
}

const deleteAdvertisement = (advertisementId: ID): Promise<void> => {
  return axios.delete(`${ADVERTISEMENT_URL}/${advertisementId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedAdvertisements = (advertisementIds: Array<ID>): Promise<void> => {
  const requests = advertisementIds.map((id) => axios.delete(`${ADVERTISEMENT_URL}/${id}`))
  return axios.all(requests).then(() => {
    toast.success("Action completed...",{position:'top-right'})
  })
}

export {
  getAdvertisements,
  deleteAdvertisement,
  deleteSelectedAdvertisements,
  getAdvertisementById,
  createAdvertisement,
  updateAdvertisement,
}
