import {useListView} from '../../core/ListViewProvider'
import {ServiceCategoriesListToolbar} from './ServiceCategoryListToolbar'
import {ServiceCategoriesListGrouping} from './ServiceCategoriesListGrouping'
import {ServiceCategoriesListSearchComponent} from './ServiceCategoriesListSearchComponent'

const ServiceCategoriesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ServiceCategoriesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ServiceCategoriesListGrouping /> : <ServiceCategoriesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ServiceCategoriesListHeader}
