import { FormattedMessage } from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {AdvertisementsListFilter} from './AdvertisementsListFilter'

const AdvertisementsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddAdvertisementModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-advertisement-table-toolbar='base'>
      <AdvertisementsListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.EXPORT' />      </button>
      {/* end::Export */}

      {/* begin::Add advertisement */}
      <button type='button' className='btn btn-primary' onClick={openAddAdvertisementModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id='ADVERTISEMENT.TABLE.CREATEADVERTISEMENT' />
      </button>
      {/* end::Add advertisement */}
    </div>
  )
}

export {AdvertisementsListToolbar}
