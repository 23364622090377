// @ts-nocheck
import {Column} from 'react-table'
import {CompanyCodeInfoCell} from './CompanyCodeInfoCell'
import {CompanyCodeLastLoginCell} from './CompanyCodeLastLoginCell'
import {CompanyCodeTwoStepsCell} from './CompanyCodeTwoStepsCell'
import {CompanyCodeActionsCell} from './CompanyCodeActionsCell'
import {CompanyCodeSelectionCell} from './CompanyCodeSelectionCell'
import {CompanyCodeCustomHeader} from './CompanyCodeCustomHeader'
import {CompanyCodeSelectionHeader} from './CompanyCodeSelectionHeader'
import {CompanyCode} from '../../core/_models'
import { FormattedMessage } from 'react-intl'
import { ResellerCustomHeader } from '../../../../reseller/resellers-list/table/columns/ResellerCustomHeader'
import { ResellerInfoCell } from '../../../../reseller/resellers-list/table/columns/ResellerInfoCell'

const companycodesColumns: ReadonlyArray<Column<CompanyCode>> = [
  {
    Header: (props) => <CompanyCodeSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <CompanyCodeSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CompanyCodeCustomHeader tableProps={props} title={<FormattedMessage id='COMPANYCODE.TABLE.COLUMN.RESERVEDDIGIT' />} className='min-w-125px' />,
    accessor: 'reserved_digit', // Access the currency name from the nested structure.
  },
  {
    Header: (props) => <CompanyCodeCustomHeader tableProps={props} title={<FormattedMessage id='COMPANYCODE.TABLE.COLUMN.COUNTRYNAME' />} className='min-w-125px' />,
    accessor: 'company.country.country_name', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  
  {
    Header: (props) => <CompanyCodeCustomHeader tableProps={props} title={<FormattedMessage id='COMPANYCODE.TABLE.COLUMN.COMPANYNAME' />} className='min-w-125px' />,
    accessor: 'company.company_name', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  {
    Header: (props) => <CompanyCodeCustomHeader tableProps={props} title={<FormattedMessage id='COMPANYCODE.TABLE.COLUMN.COUNTRYCODE' />} className='min-w-125px' />,
    accessor: 'company.country.country_telecom_code', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  // {
  //   Header: (props) => <CompanyCodeCustomHeader tableProps={props} title={<FormattedMessage id='COMPANYCODE.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <CompanyCodeCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CompanyCodeActionsCell id={props.data[props.row.index].id} />,
  },
]

export {companycodesColumns}
