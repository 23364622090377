// @ts-nocheck
import {Column} from 'react-table'
import {DistrictInfoCell} from './DistrictInfoCell'
import {DistrictLastLoginCell} from './DistrictLastLoginCell'
import {DistrictTwoStepsCell} from './DistrictTwoStepsCell'
import {DistrictActionsCell} from './DistrictActionsCell'
import {DistrictSelectionCell} from './DistrictSelectionCell'
import {DistrictCustomHeader} from './DistrictCustomHeader'
import {DistrictSelectionHeader} from './DistrictSelectionHeader'
import {District} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const districtsColumns: ReadonlyArray<Column<District>> = [
  {
    Header: (props) => <DistrictSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <DistrictSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <DistrictCustomHeader tableProps={props} title={<FormattedMessage id='DISTRICT.TABLE.COLUMN.DISTRICTNAME' />} className='min-w-125px' />,
    id: 'district_name',
    accessor:'district_name'
  },
  {
    Header: (props) => <DistrictCustomHeader tableProps={props} title={<FormattedMessage id='DISTRICT.TABLE.COLUMN.PROVINCE' />} className='min-w-125px' />,
    accessor: 'province.province_name', // Access the province name from the nested structure.
    Cell: ({ value }) => value, // Display the province name.
  },
  // {
  //   Header: (props) => <DistrictCustomHeader tableProps={props} title={<FormattedMessage id='DISTRICT.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <DistrictCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <DistrictActionsCell id={props.data[props.row.index].id} />,
  },
]

export {districtsColumns}
