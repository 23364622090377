import { useEffect, useState } from 'react';
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components';
import { initialQueryState, KTSVG } from '../../../../../../_metronic/helpers';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { useQueryResponse } from '../../core/QueryResponseProvider';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

const TransactionsListFilter = () => {
  const { updateState } = useQueryRequest();
  const { isLoading } = useQueryResponse();
  const [transactionType, setTransactionType] = useState<string | undefined>();
  const [transactionCategory, setTransactionCategory] = useState<string | undefined>();
  const [transactionPurpose, setTransactionPurpose] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setTransactionType(undefined);
    setTransactionCategory(undefined);
    setTransactionPurpose(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    updateState({ filter: undefined, ...initialQueryState });
  };

  const filterData = () => {
    updateState({
      filter: { transactionType, transactionCategory, transactionPurpose, startDate, endDate },
      ...initialQueryState
    });
  };

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.FILTEROPTION' />
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}
        {/* begin::Separator */}
        <div className='separator btransaction-gray-200'></div>
        {/* end::Separator */}
        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-transaction-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Transaction Type:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-transaction-table-filter='transaction-type'
              data-hide-search='true'
              onChange={(e) => setTransactionType(e.target.value)}
              value={transactionType}
            >
              <option value=''>All Transactions</option>
              <option value='debit'>Debited Transactions</option>
              <option value='credit'>Credited Transactions</option>
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Transaction Category:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-transaction-table-filter='transaction-category'
              data-hide-search='true'
              onChange={(e) => setTransactionCategory(e.target.value)}
              value={transactionCategory}
            >
              <option value=''>All Categories</option>
              <option value='admin-reseller'>Admin-Reseller Transactions</option>
              <option value='admin-subreseller'>Admin-SubReseller Transactions</option>
              <option value='reseller-subreseller'>Reseller-SubReseller Transactions</option>
            </select>
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Transaction Purpose:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-transaction-table-filter='transaction-purpose'
              data-hide-search='true'
              onChange={(e) => setTransactionPurpose(e.target.value)}
              value={transactionPurpose}
            >
              <option value=''>All Purposes</option>
              <option value='order'>Order Transaction</option>
              <option value='money'>Money Transaction</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-transaction-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              onClick={filterData}
              type='button'
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-transaction-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  );
};

export { TransactionsListFilter };
