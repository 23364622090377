import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialProvince, Province, Country, District } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ProvincesListLoading } from '../components/loading/ProvincesListLoading'
import { createProvince, updateProvince } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'

type Props = {
  isProvinceLoading: boolean
  province: Province
}

const editProvinceSchema = Yup.object().shape({
  profile_image_url: Yup.mixed(),
})

const ProvinceEditModalForm: FC<Props> = ({ province, isProvinceLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const COUNTRY_URL = `${API_URL}/admin/countries`;
  const intl = useIntl()
   // Function to handle image selection
   const [selectedImage, setSelectedImage] = useState<string | null>(null);


  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [countries, setCountries] = useState<[Country]>();
  useEffect(() => {
    // Make the API request using Axios
    axios
      .get(COUNTRY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCountries(data.data.countries);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const [provinceForEdit] = useState<Province>({
    ...province,
    province_name: province.province_name || initialProvince.province_name,
    country : province?.country?.id  || initialProvince?.country?.id ,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: provinceForEdit,
    validationSchema: editProvinceSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values",values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateProvince(values)
        } else {
          await createProvince(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_province_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_province_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_province_header'
          data-kt-scroll-wrappers='#kt_modal_add_province_scroll'
          data-kt-scroll-offset='300px'
        >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='PROVINCE.FORM.INPUT.PROVINCENAME' />
</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({id: 'PROVINCE.FORM.PLACEHOLDER.PROVINCENAME'})}
              {...formik.getFieldProps('province_name')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.province_name  && formik.errors.province_name  },
                {
                  'is-valid': formik.touched.province_name  && !formik.errors.province_name ,
                }
              )}
              type='text'
              name='province_name'
              autoComplete='off'
              disabled={formik.isSubmitting || isProvinceLoading}
            />
            {/* end::Input */}
            {formik.touched.province_name  && formik.errors.province_name  && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.country}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">              
          <FormattedMessage id='PROVINCE.FORM.INPUT.COUNTRY' />
</label>
            <select
              {...formik.getFieldProps('country_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.country && formik.errors.country },
                {
                  'is-valid': formik.touched.country && !formik.errors.country,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isProvinceLoading}

            ><option value='' defaultValue={""}>{intl.formatMessage({id: 'FORM.GENERAL.SELECT'})}</option>
              {countries && countries.map(country => (
                <option value={country.id}>{country.country_name}</option>
              ))}
            </select>
            {formik.touched.country && formik.errors.country && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.province_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-provinces-modal-action='cancel'
            disabled={formik.isSubmitting || isProvinceLoading}
          >
          <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-provinces-modal-action='submit'
            disabled={isProvinceLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isProvinceLoading) && (
              <span className='indicator-progress'>
              <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isProvinceLoading) && <ProvincesListLoading />}
    </>
  )
}

export { ProvinceEditModalForm }
