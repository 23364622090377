/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import axios from 'axios';
import { Currency } from '../../../../app/pages/country/countries-list/core/_models';
import { FormattedMessage } from 'react-intl';
import { setCurrecyPreference } from '../../../currency/CurrencyContextProvider';

const QuickLinks: FC = () => 
{
  const [currencies, setCurrencies] = useState<[Currency]>();
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const CURRENCY_URL = `${API_URL}/admin/currencies`;
  const UPDATE_CURRENCY_URL = `${API_URL}/admin/switch-currency-preference`;

  useEffect(() => {
    axios
      .get(CURRENCY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCurrencies(data.data.currencies);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const switchCurrency =(id:any)=>{
    axios
      .get(`${UPDATE_CURRENCY_URL}/${id}`)
      .then((response) => {
        const data = response.data;
        console.log(data.data, 'it is currency_data')
        if (data.success) {
          setCurrecyPreference(data.data.currency)
          // window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
    >
      <h3 className='text-white fw-bold mb-3'><FormattedMessage id="CURRENCY.GENERAL.SELECTCURRENCY" /></h3>
      </div>

    <div className='row g-0'>
    {currencies?.map((currency) => (
          <div className='col-6' key={currency.id} onClick={e=>switchCurrency(currency.id)}>
            <a
              href='#'
              className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
            >
              <KTSVG
                path='/media/icons/duotune/finance/fin009.svg'
                className='svg-icon-3x svg-icon-primary mb-2'
              />
              <span className='fs-5 fw-bold text-gray-800 mb-0'>{currency?.name}</span>
              <span className='fs-7 text-gray-400'>{currency?.code}</span>
            </a>
          </div>
        ))}
    </div>

    <div className='py-2 text-center border-top'>
      <Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>
        View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
      </Link>
    </div>
  </div>
)
  }

export {QuickLinks}
