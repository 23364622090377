import { FormattedMessage } from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {DistrictsListFilter} from './DistrictsListFilter'

const DistrictsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddDistrictModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-district-table-toolbar='base'>
      <DistrictsListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.EXPORT' />      </button>
      {/* end::Export */}

      {/* begin::Add district */}
      <button type='button' className='btn btn-primary' onClick={openAddDistrictModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id='DISTRICT.TABLE.CREATEDISTRICT' />
      </button>
      {/* end::Add district */}
    </div>
  )
}

export {DistrictsListToolbar}
