import {useQuery} from 'react-query'
import {AdvertisementEditModalForm} from './AdvertisementEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getAdvertisementById} from '../core/_requests'

const AdvertisementEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: advertisement,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-advertisement-${itemIdForUpdate}`,
    () => {
      return getAdvertisementById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <AdvertisementEditModalForm isAdvertisementLoading={isLoading} advertisement={{id: undefined}} />
  }

  if (!isLoading && !error && advertisement) {
    console.log({advertisement, },"its advertisement")
    console.log(typeof advertisement,"its advertisement")
    return <AdvertisementEditModalForm isAdvertisementLoading={isLoading} advertisement={advertisement} />
  }

  return null
}

export {AdvertisementEditModalFormWrapper}
