/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {initialQueryState, KTSVG, useDebounce} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import { useIntl } from 'react-intl'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { FaSyncAlt } from 'react-icons/fa'

const OrdersListSearchComponent = () => {
  const intl = useIntl()
  const { updateState } = useQueryRequest()
  const { refetch, isLoading } = useQueryResponse()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ search: debouncedSearchTerm, ...initialQueryState })
    }
  }, [debouncedSearchTerm])

  return (
    <div className='card-title'>
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-order-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={intl.formatMessage({ id: 'ORDER.TABLE.SEARCHORDER' })}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Add the new button here */}
      <button
        type="button"
        className="btn btn-light-primary ms-3"
        onClick={() => refetch()}  // This triggers the refetch
        disabled={isLoading} // Disable the button while loading
      >
        <span className='svg-icon-2'><FaSyncAlt />    </span>
        {intl.formatMessage({ id: 'ORDER.TABLE.REFRESH' })}
      </button>
    </div>
  )
}


export {OrdersListSearchComponent}
