import {useQuery} from 'react-query'
import {CompanyCodeEditModalForm} from './CompanyCodeEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getCompanyCodeById} from '../core/_requests'

const CompanyCodeEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: companycode,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-companycode-${itemIdForUpdate}`,
    () => {
      return getCompanyCodeById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <CompanyCodeEditModalForm isCompanyCodeLoading={isLoading} companycode={{id: undefined}} />
  }

  if (!isLoading && !error && companycode) {
    console.log({companycode, },"its companycode")
    console.log(typeof companycode,"its companycode")
    return <CompanyCodeEditModalForm isCompanyCodeLoading={isLoading} companycode={companycode} />
  }

  return null
}

export {CompanyCodeEditModalFormWrapper}
