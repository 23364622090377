import {useListView} from '../../core/ListViewProvider'
import {OrdersListToolbar} from './OrderListToolbar'
import {OrdersListGrouping} from './OrdersListGrouping'
import {OrdersListSearchComponent} from './OrdersListSearchComponent'

const OrdersListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <OrdersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <OrdersListGrouping /> : <OrdersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {OrdersListHeader}
