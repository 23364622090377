import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {TransactionsListHeader} from './components/header/TransactionsListHeader'
import {TransactionsTable} from './table/TransactionsTable'
import {TransactionEditModal} from './transaction-edit-modal/TransactionEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const TransactionsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <TransactionsListHeader />
        <TransactionsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TransactionEditModal />}
    </>
  )
}

const TransactionsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TransactionsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TransactionsListWrapper}
