import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import { CurrencyProvider } from '../_metronic/currency/CurrencyContextProvider'
import { requestForTokenAndPermission } from './helpers/firebase'
import Notification from './helpers/components/Notification'
import { Toaster } from 'react-hot-toast'
import 'primereact/resources/themes/saga-blue/theme.css';             // Theme
import 'primereact/resources/primereact.min.css';                      // Core styles
import 'primeicons/primeicons.css';                                     // Icons

const App = () => {
  const {currentUser} = useAuth()

  console.log(requestForTokenAndPermission(currentUser?.id))
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <CurrencyProvider>

      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      </CurrencyProvider>
    </Suspense>
  )
}

export {App}
