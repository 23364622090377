import React, {FC, createContext, useContext, useEffect} from 'react'
import {WithChildren} from '../helpers'
import { Currency } from '../../app/pages/currency/currencies-list/core/_models'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
const CURRENCY_PREFERENCE = 'CURRENCY_PREFERENCE'
const initialState: Currency = {
    id:'',
    name:'',
    code:'',
    symbol:''
}


function getConfig(): Currency {
  const ls = localStorage.getItem(CURRENCY_PREFERENCE);
  if (ls) {
    try {
      return JSON.parse(ls) as Currency;
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
function setCurrecyPreference(currency:Currency | any) {
  localStorage.setItem(
    CURRENCY_PREFERENCE,
    JSON.stringify(currency)
  );
  window.location.reload();
}


const CurrencyContext = createContext<Currency>(initialState)

const useCurrency = () => {
  return useContext(CurrencyContext);
}

const CurrencyProvider: FC<WithChildren> = ({children}) => {
  const currency = getConfig()
  return <CurrencyContext.Provider value={currency}>{children}</CurrencyContext.Provider>
}

export {CurrencyProvider, useCurrency,CurrencyContext,setCurrecyPreference}
