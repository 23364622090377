import { FormattedMessage } from 'react-intl'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { ResellersListFilter } from './ResellersListFilter'

const ResellersListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const openAddResellerModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-reseller-table-toolbar='base'>
      <ResellersListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.EXPORT' />      </button>
      {/* end::Export */}

      {/* begin::Add reseller */}
      <button type='button' className='btn btn-primary' onClick={openAddResellerModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id='RESELLER.TABLE.CREATERESELLER' />
        </button>
      {/* end::Add reseller */}
    </div>
  )
}

export { ResellersListToolbar }
