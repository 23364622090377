/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Transaction } from '../../core/_models'

type Props = {
  transaction: Transaction
}

const TransactionInfoCell: FC<Props> = ({ transaction }) =>{
  return(
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {transaction.reseller?.profile_image_url ? (
            <div className='symbol-label'>
              <img src={`${transaction.reseller?.profile_image_url}`} alt={transaction.reseller.reseller_name} className='w-100' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${transaction.reseller.reseller_name}`,
                `text-${transaction.reseller.reseller_name}`
              )}
            >
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {transaction.reseller.reseller_name}
        </a>
        {/* <span>{transaction.reseller.reseller_name}</span> */}
      </div>
    </div>
  )
}

export { TransactionInfoCell }
