import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialLanguage, Language, Province, District } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { LanguagesListLoading } from '../components/loading/LanguagesListLoading'
import { createLanguage, updateLanguage } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'

type Props = {
  isLanguageLoading: boolean
  language: Language
}

const editLanguageSchema = Yup.object().shape({
  profile_image_url: Yup.mixed(),
})

const LanguageEditModalForm: FC<Props> = ({ language, isLanguageLoading }) => {
  const intl = useIntl()
  // Function to handle image selection
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  // Function to handle image selection
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);

    }
  };

  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [languages, setLanguages] = useState<[Language]>();
  const [provinces, setProvinces] = useState<[Province]>();
  const [districts, setDistricts] = useState<[District]>();
  const [languageForEdit] = useState<Language>({
    ...language,
    language_name: language.language_name || initialLanguage.language_name,
    language_code: language.language_code || initialLanguage.language_code,
    direction: language?.direction || initialLanguage?.direction,

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: languageForEdit,
    validationSchema: editLanguageSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values", values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateLanguage(values)
        } else {
          await createLanguage(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_language_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_language_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_language_header'
          data-kt-scroll-wrappers='#kt_modal_add_language_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='LANGUAGE.FORM.INPUT.LANGUAGENAME' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'LANGUAGE.FORM.PLACEHOLDER.LANGUAGENAME' })}
              {...formik.getFieldProps('language_name')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.language_name && formik.errors.language_name },
                {
                  'is-valid': formik.touched.language_name && !formik.errors.language_name,
                }
              )}
              type='text'
              name='language_name'
              autoComplete='off'
              disabled={formik.isSubmitting || isLanguageLoading}
            />
            {/* end::Input */}
            {formik.touched.language_name && formik.errors.language_name && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_code}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='LANGUAGE.FORM.INPUT.LANGUAGECODE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'LANGUAGE.FORM.PLACEHOLDER.LANGUAGECODE' })}
              {...formik.getFieldProps('language_code')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.language_code && formik.errors.language_code },
                {
                  'is-valid': formik.touched.language_code && !formik.errors.language_code,
                }
              )}
              type='text'
              name='language_code'
              autoComplete='off'
              disabled={formik.isSubmitting || isLanguageLoading}
            />
            {/* end::Input */}
            {formik.touched.language_code && formik.errors.language_code && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_code}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='LANGUAGE.FORM.INPUT.DIRECTION' />
          </label>
            <select
            defaultValue={language.direction}
              {...formik.getFieldProps('direction')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.direction && formik.errors.direction },
                {
                  'is-valid': formik.touched.direction && !formik.errors.direction,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLanguageLoading}

            ><option disabled selected >{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              <option value="rtl">(RTL)RIGHT TO LEFT</option>
              <option value="ltr">(LTR)LEFT TO RIGHT</option>
            </select>
            {formik.touched.direction && formik.errors.direction && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_code}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-languages-modal-action='cancel'
            disabled={formik.isSubmitting || isLanguageLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-languages-modal-action='submit'
            disabled={isLanguageLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isLanguageLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLanguageLoading) && <LanguagesListLoading />}
    </>
  )
}

export { LanguageEditModalForm }
