import {AfghanNetResponse, ID, Response} from '../../../../../_metronic/helpers'
import { Currency } from '../../../country/countries-list/core/_models'
import { Service } from '../../../service/services-list/core/_models'
// import { District, Province } from '../../../bundle/bundles-list/core/_models'
export type Bundle = {
  id?: any
  bundle_name?: string
  bundle_code?: string;
  service_id?: number;
  bundle_title?: string;
  bundle_description?: string;
  validity_type?:any;
  bundle_type?:any;
  admin_buying_price?:string // this is the price for the owner
  buying_price?: string; // this is the price which a reseller buys from the admin
  selling_price?: string; // this is the price which a reseller sell to a customer
  amount?:string;
  bundle_image_url?: string | null;
  currency_id?: number;
  expired_date?: string | null;
  service?: Service;
  currency?:Currency
  status?:any
  updated_at?: Date
  created_at?: Date
}

export type BundlesQueryResponse = AfghanNetResponse<Bundle[]>

export const initialBundle: Bundle = {
  id: '',
  bundle_name: '',
  bundle_code: "quia",
  service_id: 2,
  bundle_title: "",
  bundle_description: "",
  validity_type:"",
  admin_buying_price:"",
  buying_price: "",
  selling_price: "",
  bundle_image_url: null,
  currency_id: 2,
  service:{
    id: '',
    service_name: '',
    service_category: {
      id: '',
      category_name: ''
    },
    company: {
      id: '',
      company_name: "",
      company_logo: "",
      country: {
        country_name: '',
        id: '',
        language_id: 1,
        currency: {
          id: '',
          name: '',
          code: '',
          country_id: 1,
          exchange_rate_per_usd: '',
        },
      },
    },
  },
  currency:{
    id: '',
    name: '',
    code: '',
    country_id: 1,
    exchange_rate_per_usd: '',
  }
}
