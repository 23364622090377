/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { Currency } from '../../country/countries-list/core/_models';
import { Reseller } from '../resellers-list/core/_models';
import { useNavigate } from 'react-router-dom';
import { openWhatsApp } from '../../../../_metronic/helpers';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

const transactionFormValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Amount is required')
    .positive('Amount must be a positive number')
    .min(0.01, 'Amount must be at least 0.01'),
});

type Props = {
  isResellerLoading: boolean;
  reseller: Reseller | any;
};

const ResellerBalanceTransaction: React.FC<Props> = ({ reseller, isResellerLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const [currencies, setCurrencies] = useState<[Currency]>();
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [showPasswordForm, setPasswordForm] = useState<boolean>(true);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const navigate = useNavigate();

  const CURRENCY_URL = `${API_URL}/admin/currencies`;

  useEffect(() => {
    axios
      .get(CURRENCY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setLoading2(false)
          setCurrencies(data.data.currencies);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    if (transactionSuccess) {
      // Optionally, you can perform any additional actions or navigate to a different page here.
      // For example, you can use a library like react-router to navigate to another page.
      // Example using react-router: history.push('/success-page');
    }
  }, [transactionSuccess]);

  const handleTransactionSubmit = async (values: any) => {
    const resutl = await Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to credit an amount of ${reseller.currency.code} ${values.amount} to ${reseller.reseller_name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, credit it!',
    })
    if (resutl.isConfirmed) {
      const transactionData = {
        amount: values.amount,
        reseller_id: reseller.id, // Assuming you can access the reseller ID from props
        status: 'credit', // Hardcoded to "credit"
        currency_id: values.currencyId,
      };

      setLoading2(true);
      setHideButton(true);


      // Perform the POST request
      axios
        .post(`${API_URL}/admin/transactions/credit-balance`, transactionData)
        .then((response) => {
          if (response.data.success == true) {
            toast.success(response?.data?.message, { position: 'top-right' })
          } else {
            toast.error(response?.data?.message, { position: 'top-right' })
          }
          // Create a WhatsApp message using the transaction data
          const responseData = response.data.data.reseller_balance_transaction;
          // const whatsappMessage = `Transaction Details\nAmount of: ${responseData?.amount}${responseData?.currency.code}\n Credit to Reseller ID: ${responseData.reseller.id}\nReseller Name: ${responseData.reseller.reseller_name}\nReseller Phone: ${responseData.reseller.phone}`;

          // Open a WhatsApp window with the message
          // openWhatsApp(responseData.reseller.phone, whatsappMessage);

          // Handle the response as needed
          setTransactionSuccess(true); // Set the success state to true

          setTimeout(() => {
            setTransactionSuccess(false); // Hide the success alert after 4 seconds
            setHideButton(false); // Show the button again
          }, 4000);
        })
        .catch((error) => {
          // Handle errors
          console.error('Transaction failed', error);
        })
        .finally(() => {
          setLoading2(false);
          formik.resetForm(); // Reset the formik form
          const resutl = Swal.fire({
            title: 'Amount Credited Successfully',
            text: `Credited Successfully`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Close',
          })
          // window.location.reload(); 
        });
    } else {
      return
    }
  };

  const handleDebitTransactionSubmit = async (values: any) => {
    const resutl = await Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to debit an amount of ${reseller.currency.code} ${values.amount} from ${reseller.reseller_name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, debit it!',
    })
    if (resutl.isConfirmed) {
      const transactionData = {
        amount: values.amount,
        reseller_id: reseller.id, // Assuming you can access the reseller ID from props
        status: 'debit', // Hardcoded to "debit"
        currency_id: values.currencyId,
      };

      setLoading3(true);
      setHideButton(true);


      // Perform the POST request
      axios
        .post(`${API_URL}/admin/transactions/debit-balance`, transactionData)
        .then((response) => {
          if (response.data.success == true) {
            toast.success(response?.data?.message, { position: 'top-right' })
          } else {
            toast.error(response?.data?.message, { position: 'top-right' })
          }
          // Create a WhatsApp message using the transaction data
          const responseData = response.data.data.reseller_balance_transaction;
          // const whatsappMessage = `Transaction Details\nAmount of: ${responseData?.amount}${responseData?.currency.code}\n debit to Reseller ID: ${responseData.reseller.id}\nReseller Name: ${responseData.reseller.reseller_name}\nReseller Phone: ${responseData.reseller.phone}`;

          // Open a WhatsApp window with the message
          // openWhatsApp(responseData.reseller.phone, whatsappMessage);

          // Handle the response as needed
          setTransactionSuccess(true); // Set the success state to true

          setTimeout(() => {
            setTransactionSuccess(false); // Hide the success alert after 4 seconds
            setHideButton(false); // Show the button again
          }, 4000);
        })
        .catch((error) => {
          // Handle errors
          console.error('Transaction failed', error);
        })
        .finally(() => {
          setLoading3(false);
          debitBalanceFormik.resetForm(); // Reset the formik form
          const resutl = Swal.fire({
            title: 'Amount Debited Successfully',
            text: `Debited Successfully`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Close',
          })
          // window.location.reload(); 
        });
    } else {
      return
    }
  };
  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: transactionFormValidationSchema,
    onSubmit: handleTransactionSubmit,
  });
  const debitBalanceFormik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: transactionFormValidationSchema,
    onSubmit: handleDebitTransactionSubmit,
  });
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'><FormattedMessage id='RESELLER.BALANCETRANSACTION.ADDBALANCE' /></h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center'>
            <div
              id='kt_signin_email_edit'
              className={'flex-row-fluid '}
            >
              <form
                onSubmit={formik.handleSubmit}
                id='kt_signin_change_email'
                className='form'
                noValidate
              >
                <div className='d-flex flex-wrap align-items-center mb-10'>
                  <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                    <div className='fs-6 fw-bolder mb-1'><FormattedMessage id='RESELLER.BALANCETRANSACTION.ADDBALANCE' /></div>
                    <div className='fw-bold text-gray-600'><FormattedMessage id='RESELLER.BALANCETRANSACTION.YOUCANCREDIT' /></div>
                  </div>
                  <div
                    id='kt_signin_password_edit'
                    className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                  >
                    <div className='row mb-1'>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='amount' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.BALANCETRANSACTION.AMOUNT' />
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            id='amount'
                            {...formik.getFieldProps('amount')}
                          />
                          {formik.touched.amount && formik.errors.amount && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.amount}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.BALANCETRANSACTION.SELECTCURRENCY' />
                          </label>
                          <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            {...formik.getFieldProps('currencyId')}
                          >
                            <option value='' disabled>-- Select --</option>
                            {currencies && currencies.map(currency => (
                              <option disabled selected={(reseller.currency.id == currency.id)} key={currency.id} value={currency.id}>{currency.name} - {currency.symbol}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.BALANCETRANSACTION.CONFRIMADMINPASSWORD' />
                          </label>
                          <input
                            type='password'
                            className='form-control form-control-lg form-control-solid '
                            id='confirmpassword'
                            {...formik.getFieldProps('passwordConfirmation')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <button
                        id='kt_password_submit'
                        type='submit'
                        className='btn btn-primary me-2 px-6'
                      >
                        {!loading2 && <FormattedMessage id='RESELLER.BALANCETRANSACTION.CONFIRMTRANSACTION' />}
                        {loading2 && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>

          <div className='separator separator-dashed my-6'></div>
        </div>
      </div>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'><FormattedMessage id='RESELLER.BALANCETRANSACTION.DEBITBALANCE' /></h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center'>
            <div
              id='kt_signin_email_edit'
              className={'flex-row-fluid '}
            >
              <form
                onSubmit={debitBalanceFormik.handleSubmit}
                id='kt_signin_change_email'
                className='form'
                noValidate
              >
                <div className='d-flex flex-wrap align-items-center mb-10'>
                  <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                    <div className='fs-6 fw-bolder mb-1'><FormattedMessage id='RESELLER.BALANCETRANSACTION.DEBITBALANCE' /></div>
                    <div className='fw-bold text-gray-600'><FormattedMessage id='RESELLER.BALANCETRANSACTION.YOUCANDEBIT' /></div>
                  </div>
                  <div
                    id='kt_signin_password_edit'
                    className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                  >
                    <div className='row mb-1'>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='amount' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.BALANCETRANSACTION.AMOUNT' />
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            id='amount'
                            {...debitBalanceFormik.getFieldProps('amount')}
                          />
                          {debitBalanceFormik.touched.amount && debitBalanceFormik.errors.amount && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{debitBalanceFormik.errors.amount}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.BALANCETRANSACTION.SELECTCURRENCY' />
                          </label>
                          <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            {...debitBalanceFormik.getFieldProps('currencyId')}
                          >
                            <option value='' disabled>-- Select --</option>
                            {currencies && currencies.map(currency => (
                              <option disabled selected={(reseller.currency.id == currency.id)} key={currency.id} value={currency.id}>{currency.name} - {currency.symbol}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.BALANCETRANSACTION.CONFRIMADMINPASSWORD' />
                          </label>
                          <input
                            type='password'
                            className='form-control form-control-lg form-control-solid '
                            id='confirmpassword'
                            {...debitBalanceFormik.getFieldProps('passwordConfirmation')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <button
                        id='kt_password_submit'
                        type='submit'
                        className='btn btn-primary me-2 px-6'
                      >
                        {!loading3 && <FormattedMessage id='RESELLER.BALANCETRANSACTION.CONFIRMTRANSACTION' />}
                        {loading3 && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>

          <div className='separator separator-dashed my-6'></div>
        </div>
      </div>
    </div>
  );
};

export { ResellerBalanceTransaction };
