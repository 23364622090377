import {useQuery} from 'react-query'
import {OrderEditModalForm} from './OrderEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getOrderById} from '../core/_requests'

const OrderEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: order,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-order-${itemIdForUpdate}`,
    () => {
      return getOrderById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <OrderEditModalForm isOrderLoading={isLoading} order={{id: undefined}} />
  }

  if (!isLoading && !error && order) {
    console.log({order, },"its order")
    console.log(typeof order,"its order")
    return <OrderEditModalForm isOrderLoading={isLoading} order={order} />
  }

  return null
}

export {OrderEditModalFormWrapper}
