import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { useListView } from '../../core/ListViewProvider';
import { TransactionsListFilter } from './TransactionsListFilter';
import { useQueryResponse } from '../../core/QueryResponseProvider';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { initialQueryState } from '../../../../../../_metronic/helpers';
import { Calendar } from 'primereact/calendar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const TransactionsListToolbar = () => {
  const { setItemIdForUpdate } = useListView();
  const transactionData = useQueryResponse();
  const { updateState } = useQueryRequest();
  const [dates, setDates] = useState<Date[]>([]);

  const handleExportToExcel = () => {
    updateState({
      filter: { isExport: true },
      ...initialQueryState,
    });

    const transactions = transactionData.response?.data.reseller_balance_transactions || [];
    const flattenedTransactions = transactions.map(transaction => ({
      'Transaction ID': transaction.id,
      'Reseller ID': transaction.reseller_id,
      'Reseller Name': transaction.reseller.reseller_name,
      'Reseller Phone': transaction.reseller.phone,
      'Transaction Amount': transaction.amount,
      'Currency': transaction.reseller.user.currency.symbol,
      'Transaction Status': transaction.status,
      'Transaction Date': dayjs(transaction.created_at).format('MMMM DD, YYYY HH:mm:ss'),
      'Bundle ID': transaction?.order?.bundle?.id,
      'Bundle Code': transaction?.order?.bundle?.bundle_code,
      'Bundle Title': transaction?.order?.bundle?.bundle_title,
      'Bundle Description': transaction?.order?.bundle?.bundle_description,
      'Bundle Validity': transaction?.order?.bundle?.validity_type,
      'Bundle Buying Price': transaction?.order?.bundle?.buying_price ? 
        transaction?.order?.bundle?.buying_price as number *
        (transaction?.order?.bundle?.prefered_currency.exchange_rate_per_usd as number /
          transaction?.currency?.exchange_rate_per_usd as number) 
        : '',
      'Bundle Selling Price': transaction?.order?.bundle?.selling_price ? 
        transaction?.order?.bundle?.selling_price as number *
        (transaction?.order?.bundle?.prefered_currency.exchange_rate_per_usd as number /
          transaction?.currency?.exchange_rate_per_usd as number) 
        : '',    
      'Service Category': transaction?.order?.bundle?.service.service_category.category_name,
      'Service Company': transaction?.order?.bundle?.service.company.company_name,
    }));
  
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(flattenedTransactions);
    XLSX.utils.book_append_sheet(wb, ws, 'Transactions Data');
    XLSX.writeFile(wb, 'transactions.xlsx');
  };
  

 
  dayjs.extend(utc);
dayjs.extend(timezone);

const handleCalendarChange = (e: any) => {
  // Update query state when calendar changes
  setDates(e.value);

  let startDateUTC = null;
  let endDateUTC = null;

  if (e.value && e.value.length > 0) {
      // Convert selected dates to Afghanistan time zone
      const startDateAfghanistan = dayjs(e.value[0]).tz('Asia/Kabul');
      const endDateAfghanistan = e.value[1] != null ? dayjs(e.value[1]).tz('Asia/Kabul') : null;

      // Convert Afghanistan time zone dates to UTC
      startDateUTC = startDateAfghanistan.utc().format('YYYY-MM-DD HH:mm:ss');
      endDateUTC = endDateAfghanistan !== null ? endDateAfghanistan.utc().format('YYYY-MM-DD HH:mm:ss') : null;
  }

  updateState({
      filter: {
          startDate: startDateUTC,
          endDate: endDateUTC
      },
      ...initialQueryState,
  });
};


  return (
    <div className='d-flex justify-content-end w-100' data-kt-transaction-table-toolbar='base'>
      
      <Calendar
  className='mx-2 col-4 col-sm-7'
  value={dates}
  onChange={handleCalendarChange}
  selectionMode='range'
  showTime
  hourFormat='12'
  placeholder='Search By Date (Select start and end)'
  showIcon
  showButtonBar
  
/>

      <TransactionsListFilter />
      
      <button type='button' className='btn btn-light-primary me-3' onClick={handleExportToExcel}>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.EXPORT' />
      </button>
    </div>
  );
};

export { TransactionsListToolbar };
