import {ID, AfghanNetResponse, Response} from '../../../../../_metronic/helpers'
import { ServiceCategorySubType } from '../../../servicecategorysubtype/servicecategorysubtypes-list/core/_models'
// import { District, Province } from '../../../service-category/service-categories-list/core/_models'
export type ServiceCategory = {
  id?: any
  category_name?: string
  type?:string
  service_category_sub_type?:ServiceCategorySubType
  updated_at?: Date
  created_at?: Date
}

export type ServiceCategoriesQueryResponse = AfghanNetResponse<ServiceCategory[]>

export const initialServiceCategory: ServiceCategory = {
  id: '',
  category_name: '',
}
