import {useQuery} from 'react-query'
import {DistrictEditModalForm} from './DistrictEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getDistrictById} from '../core/_requests'

const DistrictEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: district,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-district-${itemIdForUpdate}`,
    () => {
      return getDistrictById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <DistrictEditModalForm isDistrictLoading={isLoading} district={{id: undefined}} />
  }

  if (!isLoading && !error && district) {
    console.log({district, },"its district")
    console.log(typeof district,"its district")
    return <DistrictEditModalForm isDistrictLoading={isLoading} district={district} />
  }

  return null
}

export {DistrictEditModalFormWrapper}
