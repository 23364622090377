/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { Currency } from '../../country/countries-list/core/_models';
import { Reseller } from '../resellers-list/core/_models';
import Swal from 'sweetalert2';

const resellerPasswordFormValidationSchema = Yup.object().shape({
  adminPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string()
    .required('New Password is required')
    .min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .required('Confirm New Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

type Props = {
  isResellerLoading: boolean;
  reseller: Reseller | any;
};

const ResellerChangePassword: React.FC<Props> = ({ reseller, isResellerLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const [loading2, setLoading2] = useState(false);
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const handleSetPasswordSubmit =async (values: any) => {
    const resutl = await Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to change password...`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, set it!',
    })
    if(resutl.isConfirmed){
      const passwordData = {
        admin_password: values.adminPassword,
        new_password: values.newPassword,
        confirm_new_password: values.confirmPassword,
        reseller_id : reseller?.id
      };
  
      setLoading2(true);
      // Perform the POST request to change the password
      axios
        .post(`${API_URL}/admin/resellers/set-reseller-password`, passwordData)
        .then((response) => {
          // Handle the response as needed
          console.log('Password changed successfully', response.data);
          setTransactionSuccess(true); // Set the success state to true
  
          setTimeout(() => {
            setTransactionSuccess(false); // Hide the success alert after 4 seconds
          }, 4000);
        })
        .catch((error) => {
          // Handle errors
          console.error('Password change failed', error);
        })
        .finally(() => {
          setLoading2(false);
          formik.resetForm(); // Reset the formik form
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      adminPassword: reseller?.account_password, // Display current password by default
      newPassword: '',
      confirmPassword: '',
      reseller_id:""
    },
    validationSchema: resellerPasswordFormValidationSchema,
    onSubmit: handleSetPasswordSubmit,
  });

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'><FormattedMessage id='RESELLER.PASSWORDSETTING.RESELLERPASSWORDSETTING' /></h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center'>
            <div
              id='kt_signin_email_edit'
              className={'flex-row-fluid '}
            >
              <form
                onSubmit={formik.handleSubmit}
                id='kt_signin_change_email'
                className='form'
                noValidate
              >
                <div className='d-flex flex-wrap align-items-center mb-10'>
                  <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                    <div className='fs-6 fw-bolder mb-1'><FormattedMessage id='RESELLER.PASSWORDSETTING.CHANGEPASSWORD' /></div>
                    <div className='fw-bold text-gray-600'><FormattedMessage id='RESELLER.PASSWORDSETTING.YOUCANCHANGE' /></div>
                  </div>
                  <div
                    id='kt_signin_password_edit'
                    className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                  >
                    <div className='row mb-1'>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='adminPassword' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.PASSWORDSETTING.CURRENTPASSWORD' />
                          </label>
                          <input
                            type='password'
                            className='form-control form-control-lg form-control-solid'
                            id='adminPassword'
                            {...formik.getFieldProps('adminPassword')}
                          />
                          {formik.touched.adminPassword && formik.errors.adminPassword && (
                            <div className='fv-plugins-message-container'>
                              {/* <div className='fv-help-block'>{formik.errors.adminPassword}</div> */}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='newPassword' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.PASSWORDSETTING.NEWPASSWORD' />
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            id='newPassword'
                            {...formik.getFieldProps('newPassword')}
                          />
                          {formik.touched.newPassword && formik.errors.newPassword && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.newPassword}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='confirmPassword' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.PASSWORDSETTING.CONFIRMPASSWORD' />
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid '
                            id='confirmPassword'
                            {...formik.getFieldProps('confirmPassword')}
                          />
                          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <button
                        id='kt_password_submit'
                        type='submit'
                        className='btn btn-primary me-2 px-6'
                      >
                        {!loading2 && <FormattedMessage id='RESELLER.PASSWORDSETTING.CHANGEPASSWORD' />}
                        {loading2 && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button
                        onClick={() => {
                          setPasswordForm(false)
                        }}
                        id='kt_password_cancel'
                        type='button'
                        className='btn btn-color-gray-400 btn-active-light-primary px-6'
                      >
                        <FormattedMessage id='APP.GENERAL.CANCEL' />
                      </button>
                    </div>
                  </div>
                  <div
                    id='kt_signin_password_button'
                    className={'ms-auto ' + (showPasswordForm && 'd-none')}
                  >
                    <button
                      onClick={() => {
                        setPasswordForm(true)
                      }}
                      className='btn btn-light btn-active-light-primary'
                    >
                      <FormattedMessage id='RESELLER.PASSWORDSETTING.CHANGEPASSWORD' />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {transactionSuccess && (
            <div className='alert alert-success mt-4' role='alert'>
              Password changed successfully!
            </div>
          )}
          <div className='separator separator-dashed my-6'></div>
        </div>
      </div>
    </div>
  );
};

export { ResellerChangePassword };
