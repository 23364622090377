import {useListView} from '../../core/ListViewProvider'
import {CompanyCodesListToolbar} from './CompanyCodeListToolbar'
import {CompanyCodesListGrouping} from './CompanyCodesListGrouping'
import {CompanyCodesListSearchComponent} from './CompanyCodesListSearchComponent'

const CompanyCodesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CompanyCodesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <CompanyCodesListGrouping /> : <CompanyCodesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CompanyCodesListHeader}
