// @ts-nocheck
import {Column} from 'react-table'
import {ProvinceInfoCell} from './ProvinceInfoCell'
import {ProvinceLastLoginCell} from './ProvinceLastLoginCell'
import {ProvinceTwoStepsCell} from './ProvinceTwoStepsCell'
import {ProvinceActionsCell} from './ProvinceActionsCell'
import {ProvinceSelectionCell} from './ProvinceSelectionCell'
import {ProvinceCustomHeader} from './ProvinceCustomHeader'
import {ProvinceSelectionHeader} from './ProvinceSelectionHeader'
import {Province} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const provincesColumns: ReadonlyArray<Column<Province>> = [
  {
    Header: (props) => <ProvinceSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ProvinceSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ProvinceCustomHeader tableProps={props} title={<FormattedMessage id='PROVINCE.TABLE.COLUMN.PROVINCENAME' />} className='min-w-125px' />,
    id: 'province_name',
    accessor:'province_name'
  },
  {
    Header: (props) => <ProvinceCustomHeader tableProps={props} title={<FormattedMessage id='PROVINCE.TABLE.COLUMN.COUNTRY' />} className='min-w-125px' />,
    accessor: 'country.country_name', // Access the country name from the nested structure.
    Cell: ({ value }) => value, // Display the country name.
  },
  // {
  //   Header: (props) => <ProvinceCustomHeader tableProps={props} title={<FormattedMessage id='PROVINCE.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <ProvinceCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProvinceActionsCell id={props.data[props.row.index].id} />,
  },
]

export {provincesColumns}
