import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialOrder, Order } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { OrdersListLoading } from '../components/loading/OrdersListLoading'
import { createOrder, updateOrder } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import { Company, Service } from '../../../service/services-list/core/_models'
import { ServiceCategory } from '../../../service-category/service-categories-list/core/_models'

type Props = {
  isOrderLoading: boolean
  order: Order
}

const editOrderSchema = Yup.object().shape({
  profile_image_url: Yup.mixed(),
})

const OrderEditModalForm: FC<Props> = ({ order, isOrderLoading }) => {
  const intl = useIntl()
  // Function to handle image selection
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  // Function to handle image selection
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);

    }
  };

  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [companies, setCompanies] = useState<[Company]>();
  const [services, setServices] = useState<[Service]>();
  const [orderForEdit] = useState<Order>({
    ...order,
    id: order.id || initialOrder.id,
    rechargeble_account: order.rechargeble_account || initialOrder.rechargeble_account,
    // order_description: order.order_description || initialOrder.order_description,
    // order_code: order.order_code || initialOrder.order_code,
    // buying_price: order.buying_price || initialOrder.buying_price,
    // selling_price: order.selling_price || initialOrder.selling_price,
    // currency_id: order.currency_id || initialOrder.currency_id,
    // order_image_url: order.order_image_url || initialOrder.order_image_url,
    // service: order.service?.id || initialOrder.service?.id,
    // service_id:order.service_id || initialOrder.service_id

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: orderForEdit,
    validationSchema: editOrderSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values", values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateOrder(values)
        } else {
          await createOrder(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_order_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_order_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_order_header'
          data-kt-scroll-wrappers='#kt_modal_add_order_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='ORDER.FORM.INPUT.ORDERTITLE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'ORDER.FORM.PLACEHOLDER.ORDERTITLE' })}
              {...formik.getFieldProps('rechargeble_account')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.rechargeble_account && formik.errors.rechargeble_account },
                {
                  'is-valid': formik.touched.rechargeble_account && !formik.errors.rechargeble_account,
                }
              )}
              type='text'
              name='rechargeble_account'
              autoComplete='off'
              disabled={formik.isSubmitting || isOrderLoading}
            />
            {/* end::Input */}
            {formik.touched.rechargeble_account && formik.errors.rechargeble_account && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_id}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
  

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-orders-modal-action='cancel'
            disabled={formik.isSubmitting || isOrderLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-orders-modal-action='submit'
            disabled={isOrderLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isOrderLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isOrderLoading) && <OrdersListLoading />}
    </>
  )
}

export { OrderEditModalForm }
