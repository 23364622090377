// @ts-nocheck
import {Column} from 'react-table'
import {ResellerInfoCell} from './ResellerInfoCell'
import {ResellerLastLoginCell} from './ResellerLastLoginCell'
import {ResellerTwoStepsCell} from './ResellerTwoStepsCell'
import {ResellerActionsCell} from './ResellerActionsCell'
import {ResellerSelectionCell} from './ResellerSelectionCell'
import {ResellerCustomHeader} from './ResellerCustomHeader'
import {ResellerSelectionHeader} from './ResellerSelectionHeader'
import {Reseller} from '../../core/_models'
import { FormattedMessage } from 'react-intl'
import { ParentResellerInfoCell } from './ParentResellerInfoCell'

const resellersColumns: ReadonlyArray<Column<Reseller>> = [
  {
    Header: (props) => <ResellerSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ResellerSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.RESELLERNAME' />} className='min-w-125px' />,
    id: 'reseller_name',
    Cell: ({...props}) => <ResellerInfoCell reseller={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.PHONE' />} className='min-w-125px' />,
    accessor: 'phone',
  },
  
  {
    Header: (props) => (
      <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.CREDITBALANCE' />} className='min-w-125px' />
    ),
    accessor: 'balance',
  },
  {
    Header: (props) => <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.CURRENCYPREFERENCE' />} className='min-w-125px' />,
    accessor: 'code',
  },
  {
    Header: (props) => <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.PARENTRESELLER' />} className='min-w-125px' />,
    id: 'parent_reseller_name',
    Cell: ({...props}) => <ParentResellerInfoCell reseller={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.LOANBALANCE' />} className='min-w-125px' />
    ),
    accessor: 'loan_balance',
  },
  {
    Header: (props) => (
      <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.COUNTRY' />} className='min-w-125px' />
    ),
    accessor: 'country',
  },
  // {
  //   Header: (props) => (
  //     <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.PROVINCE' />} className='min-w-125px' />
  //   ),
  //   accessor: 'province',
  // },
  // {
  //   Header: (props) => (
  //     <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='RESELLER.TABLE.COLUMN.DISTRICT' />} className='min-w-125px' />
  //   ),
  //   accessor: 'districts',
  // },
  {
    Header: (props) => (
      <ResellerCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.STATUS' />}
        className='min-w-125px'
      />
    ),
    accessor: 'status',
    Cell: ({ value }) => {
      const renderStatusBadge = () => {
        if (value == 1) {
          return <span className="badge badge-success">Active</span>;
        } else if (value == 0) {
          return <span className="badge badge-danger">De-Active</span>;
        }
        return null; // Handle other cases or return nothing
      };

      return renderStatusBadge();
    },
  },
  
  {
    Header: (props) => (
      <ResellerCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ResellerActionsCell id={props.data[props.row.index].id} status={props.data[props.row.index].status}/>,
  },
]

export {resellersColumns}
