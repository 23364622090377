// @ts-nocheck
import {Column} from 'react-table'
import {ServiceInfoCell} from './ServiceInfoCell'
import {ServiceLastLoginCell} from './ServiceLastLoginCell'
import {ServiceTwoStepsCell} from './ServiceTwoStepsCell'
import {ServiceActionsCell} from './ServiceActionsCell'
import {ServiceSelectionCell} from './ServiceSelectionCell'
import {ServiceCustomHeader} from './ServiceCustomHeader'
import {ServiceSelectionHeader} from './ServiceSelectionHeader'
import {Service} from '../../core/_models'
import { FormattedMessage } from 'react-intl'
import { ResellerCustomHeader } from '../../../../reseller/resellers-list/table/columns/ResellerCustomHeader'
import { ResellerInfoCell } from '../../../../reseller/resellers-list/table/columns/ResellerInfoCell'

const servicesColumns: ReadonlyArray<Column<Service>> = [
  {
    Header: (props) => <ServiceSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ServiceSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ServiceCustomHeader tableProps={props} title={<FormattedMessage id='SERVICE.TABLE.COLUMN.COMPANYNAME' />} className='min-w-125px' />,
    accessor: 'company.company_name', // Access the currency name from the nested structure.
    Cell: ({...props}) => <ServiceInfoCell service={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ServiceCustomHeader tableProps={props} title={<FormattedMessage id='SERVICE.TABLE.COLUMN.COUNTRY' />} className='min-w-125px' />,
    accessor: 'company.country.country_name', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  {
    Header: (props) => <ServiceCustomHeader tableProps={props} title={<FormattedMessage id='SERVICE.TABLE.COLUMN.SERVICECATEGORY' />} className='min-w-125px' />,
    accessor: 'service_category.category_name', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  // {
  //   Header: (props) => <ServiceCustomHeader tableProps={props} title={<FormattedMessage id='SERVICE.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <ServiceCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ServiceActionsCell id={props.data[props.row.index].id} />,
  },
]

export {servicesColumns}
