/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Country } from '../../core/_models'

type Props = {
  country: Country
}

const CountryInfoCell: FC<Props> = ({ country }) =>{
  return(
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className=' me-3'>
        <a href='#'>
          {country.country_flag_image_url ? (
            <div className='symbol-label'>
              <img src={`${country.country_flag_image_url}`} alt={country.country_name} className='w-25' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${country.country_name}`,
                `text-${country.country_name}`
              )}
            >
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {country.country_name}
        </a>
        <span>{country.country_name}</span>
      </div>
    </div>
  )
}

export { CountryInfoCell }
