import {useListView} from '../../core/ListViewProvider'
import {ProvincesListToolbar} from './ProvinceListToolbar'
import {ProvincesListGrouping} from './ProvincesListGrouping'
import {ProvincesListSearchComponent} from './ProvincesListSearchComponent'

const ProvincesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ProvincesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ProvincesListGrouping /> : <ProvincesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ProvincesListHeader}
