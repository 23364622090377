/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { Currency } from '../../country/countries-list/core/_models';
import { Reseller } from '../resellers-list/core/_models';
import Swal from 'sweetalert2';

const resellerPinFormValidationSchema = Yup.object().shape({
  newPin: Yup.string()
    .required('New Pin is required')
    .matches(/^\d{4}$/, 'Pin must be exactly 4 digits'),
  confirmPin: Yup.string()
    .required('Confirm New Pin is required')
    .oneOf([Yup.ref('newPin'), null], 'Pins must match'),
});

type Props = {
  isResellerLoading: boolean;
  reseller: Reseller | any;
};

const ResellerChangePin: React.FC<Props> = ({ reseller, isResellerLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const [loading2, setLoading2] = useState(false);
  const [showPinForm, setPinForm] = useState<boolean>(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const handleSetPinSubmit = async (values: any) => {
    const resutl = await Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to change pin...`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, set it!',
    })
    if (resutl.isConfirmed) {
      const pinData = {
        admin_pin: values.adminPin,
        new_pin: values.newPin,
        confirm_new_pin: values.confirmPin,
        reseller_id: reseller?.id
      };

      setLoading2(true);
      // Perform the POST request to change the pin
      axios
        .post(`${API_URL}/admin/resellers/set-reseller-pin`, pinData)
        .then((response) => {
          // Handle the response as needed
          console.log('Pin changed successfully', response.data);
          setTransactionSuccess(true); // Set the success state to true

          setTimeout(() => {
            setTransactionSuccess(false); // Hide the success alert after 4 seconds
          }, 4000);
        })
        .catch((error) => {
          // Handle errors
          console.error('Pin change failed', error);
        })
        .finally(() => {
          setLoading2(false);
          formik.resetForm(); // Reset the formik form
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      adminPin: reseller?.account_pin, // Display current pin by default
      newPin: '',
      confirmPin: '',
      reseller_id: ""
    },
    validationSchema: resellerPinFormValidationSchema,
    onSubmit: handleSetPinSubmit,
  });

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'><FormattedMessage id='RESELLER.PINSETTING.RESELLERPINSETTING' /></h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center'>
            <div
              id='kt_signin_email_edit'
              className={'flex-row-fluid '}
            >
              <form
                onSubmit={formik.handleSubmit}
                id='kt_signin_change_email'
                className='form'
                noValidate
              >
                <div className='d-flex flex-wrap align-items-center mb-10'>
                  <div id='kt_signin_pin' className={' ' + (showPinForm && 'd-none')}>
                    <div className='fs-6 fw-bolder mb-1'><FormattedMessage id='RESELLER.PINSETTING.CHANGEPIN' /></div>
                    <div className='fw-bold text-gray-600'><FormattedMessage id='RESELLER.PINSETTING.YOUCANCHANGE' /></div>
                  </div>
                  <div
                    id='kt_signin_pin_edit'
                    className={'flex-row-fluid ' + (!showPinForm && 'd-none')}
                  >
                    <div className='row mb-1'>
                      
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='newPin' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.PINSETTING.NEWPIN' />
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            id='newPin'
                            {...formik.getFieldProps('newPin')}
                          />
                          {formik.touched.newPin && formik.errors.newPin && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.newPin}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='confirmPin' className='form-label fs-6 fw-bolder mb-3'>
                            <FormattedMessage id='RESELLER.PINSETTING.CONFIRMPIN' />
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid '
                            id='confirmPin'
                            {...formik.getFieldProps('confirmPin')}
                          />
                          {formik.touched.confirmPin && formik.errors.confirmPin && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.confirmPin}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <button
                        id='kt_pin_submit'
                        type='submit'
                        className='btn btn-primary me-2 px-6'
                      >
                        {!loading2 && <FormattedMessage id='RESELLER.PINSETTING.CHANGEPIN' />}
                        {loading2 && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button
                        onClick={() => {
                          setPinForm(false)
                        }}
                        id='kt_pin_cancel'
                        type='button'
                        className='btn btn-color-gray-400 btn-active-light-primary px-6'
                      >
                        <FormattedMessage id='APP.GENERAL.CANCEL' />
                      </button>
                    </div>
                  </div>
                  <div
                    id='kt_signin_pin_button'
                    className={'ms-auto ' + (showPinForm && 'd-none')}
                  >
                    <button
                      onClick={() => {
                        setPinForm(true)
                      }}
                      className='btn btn-light btn-active-light-primary'
                    >
                      <FormattedMessage id='RESELLER.PINSETTING.CHANGEPIN' />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {transactionSuccess && (
            <div className='alert alert-success mt-4' role='alert'>
              Pin changed successfully!
            </div>
          )}
          <div className='separator separator-dashed my-6'></div>
        </div>
      </div>
    </div>
  );
};

export { ResellerChangePin };
