import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import ResellersPage from '../pages/reseller/ResellerPage'
import CountriesPage from '../pages/country/CountryPage'
import ProvincesPage from '../pages/province/ProvincePage'
import DistrictsPage from '../pages/district/DistrictPage'
import LanguagesPage from '../pages/language/LanguagePage'
import ServiceCategoryPage from '../pages/service-category/ServiceCategoryPage'
import ServicesPage from '../pages/service/ServicePage'
import BundlesPage from '../pages/bundle/BundlePage'
import AdvertisementsPage from '../pages/advertisement/AdvertisementPage'
import CurrenciesPage from '../pages/currency/CurrencyPage'
import OrdersPage from '../pages/order/OrderPage'
import CompaniesPage from '../pages/company/CompanyPage'
import CompanyCodesPage from '../pages/companycode/CompanyCodePage'
import TransactionsPage from '../pages/transaction/TransactionPage'
import AppSettingPage from '../pages/app-setting/AppSettingPage'
import ServiceCategorySubTypesPage from '../pages/servicecategorysubtype/ServiceCategorySubTypePage'
// import OrdersPage from '../pages/order/OrdersPage'
// import BundlesPage from '../pages/bundle/BundlesPage'
// import CountriesPage from '../pages/country/CountriesPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='admin/pages/resellers/*'
          element={
            <SuspensedView>
              <ResellersPage />
            </SuspensedView>
          }
        />

        <Route
          path='admin/pages/orders/*'
          element={
            <SuspensedView>
              {/* <OrdersPage /> */}
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/bundles/*'
          element={
            <SuspensedView>
              {/* <BundlesPage /> */}
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/countries'
          element={
            <SuspensedView>
              <CountriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/provinces'
          element={
            <SuspensedView>
              <ProvincesPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/districts'
          element={
            <SuspensedView>
              <DistrictsPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/languages'
          element={
            <SuspensedView>
              <LanguagesPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/service_category'
          element={
            <SuspensedView>
              <ServiceCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/service_category_sub_type'
          element={
            <SuspensedView>
              <ServiceCategorySubTypesPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/services'
          element={
            <SuspensedView>
              <ServicesPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/bundles'
          element={
            <SuspensedView>
              <BundlesPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/advertisements'
          element={
            <SuspensedView>
              <AdvertisementsPage />
            </SuspensedView>
          }
        />
          <Route
          path='admin/pages/app_setting'
          element={
            <SuspensedView>
              <AppSettingPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/currencies'
          element={
            <SuspensedView>
              <CurrenciesPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/orders'
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />
                <Route
          path='admin/pages/transactions/*'
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/pages/companies'
          element={
            <SuspensedView>
              <CompaniesPage />
            </SuspensedView>
          }
        />
          <Route
          path='admin/pages/company_codes'
          element={
            <SuspensedView>
              <CompanyCodesPage />
            </SuspensedView>
          }
        />
          <Route
          path='admin/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
          <Route
          path='admin/pages/acount/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
