/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { Reseller } from '../../core/_models'

type Props = {
  reseller: Reseller
}

const ParentResellerInfoCell: FC<Props> = ({ reseller }) => {
  return (
    <div className='d-flex align-items-center bg-info p-2 rounded'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {reseller.parent_reseller_profile_image_url ? (
            <div className='symbol-label'>
              <img
                src={`${reseller.parent_reseller_profile_image_url}`}
                alt={reseller.parent_reseller_name}
                className='w-100'
              />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${reseller.parent_reseller_name}`,
                `text-${reseller.parent_reseller_name}`
              )}
            ></div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column bg-info p-2 rounded shadow-sm'>
        <span className='text-white fw-bold mb-1'>
          {reseller.parent_reseller_name}
        </span>
        <span className='text-white'>
          {reseller.parent_reseller_phone}
        </span>
      </div>
    </div>
  )
}

export { ParentResellerInfoCell }
