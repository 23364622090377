import {useListView} from '../../core/ListViewProvider'
import {AdvertisementsListToolbar} from './AdvertisementListToolbar'
import {AdvertisementsListGrouping} from './AdvertisementsListGrouping'
import {AdvertisementsListSearchComponent} from './AdvertisementsListSearchComponent'

const AdvertisementsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <AdvertisementsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <AdvertisementsListGrouping /> : <AdvertisementsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {AdvertisementsListHeader}
