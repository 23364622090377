// @ts-nocheck
import {Column} from 'react-table'
import {CurrencyInfoCell} from './CurrencyInfoCell'
import {CurrencyLastLoginCell} from './CurrencyLastLoginCell'
import {CurrencyTwoStepsCell} from './CurrencyTwoStepsCell'
import {CurrencyActionsCell} from './CurrencyActionsCell'
import {CurrencySelectionCell} from './CurrencySelectionCell'
import {CurrencyCustomHeader} from './CurrencyCustomHeader'
import {CurrencySelectionHeader} from './CurrencySelectionHeader'
import {Currency} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const currenciesColumns: ReadonlyArray<Column<Currency>> = [
  {
    Header: (props) => <CurrencySelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <CurrencySelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CurrencyCustomHeader tableProps={props} title={<FormattedMessage id='CURRENCY.TABLE.COLUMN.CURRENCYNAME' />} className='min-w-125px' />,
    id: 'name',
    accessor:'name'
  },
  {
    Header: (props) => <CurrencyCustomHeader tableProps={props} title={<FormattedMessage id='CURRENCY.TABLE.COLUMN.CURRENCYCODE' />} className='min-w-125px' />,
    accessor: 'code', // Access the currency name from the nested structure.

  },
  {
    Header: (props) => <CurrencyCustomHeader tableProps={props} title={<FormattedMessage id='CURRENCY.TABLE.COLUMN.EXCHANGERATE' />} className='min-w-125px' />,
    accessor: 'exchange_rate_per_usd', // Access the currency name from the nested structure.

  },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title={<FormattedMessage id='CURRENCY.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <CurrencyCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CurrencyActionsCell id={props.data[props.row.index].id} />,
  },
]

export {currenciesColumns}
