/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteOrder, confirmOrder, vpnconfirmOrder, rejectOrder, setOrderUnderProcess } from '../../core/_requests'
import { FormattedMessage } from 'react-intl'
import { Order } from '../../core/_models'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'

type Props = {
  order: Order
}

const OrderActionsCell: FC<Props> = ({ order }) => {
  const id = order.id
  const status = order.status
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

// Safely check if the service category sub-type name is 'vpn' (case-insensitive)
const isVpnOrder = order?.bundle?.service?.service_category?.servicecategorysubtype?.sub_type_name?.toLowerCase() == 'vpn';


  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteOrder(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    },
  })

  const confirmDelete = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    })

    if (result.isConfirmed) {
      deleteItem.mutate()
    }
  }

  const confirmResellerOrder = useMutation(() => confirmOrder(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    },
  })

  const confirmingOrder = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to confirm this order!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, confirm it!',
    })

    if (result.isConfirmed) {
      confirmResellerOrder.mutate()
    }
  }

  const vpnConfirmResellerOrder = useMutation((formData: any) => vpnconfirmOrder(formData), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    },
  })

  const confirmingVpnOrder = async () => {
    const { value: formValues } = await Swal.fire({
      title: 'Confirm VPN Order',
      html: `
        <input type="text" id="vpn_activation_link" class="swal2-input" placeholder="Activation Link">
        <input type="file" id="vpn_activation_qr_code_image" class="swal2-file" accept="image/*" />
      `,
      focusConfirm: false,
      preConfirm: () => {
        const vpn_activation_link = (Swal.getPopup()?.querySelector('#vpn_activation_link') as HTMLInputElement).value
        const vpn_activation_qr_code_image = (Swal.getPopup()?.querySelector('#vpn_activation_qr_code_image') as HTMLInputElement).files?.[0]

        if (!vpn_activation_qr_code_image) {
          Swal.showValidationMessage('Please select an image')
        }

        return { vpn_activation_link, vpn_activation_qr_code_image }
      },
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, confirm VPN!',
    })

    if (formValues) {
      const formData = new FormData()
      formData.append('id', id)
      formData.append('vpn_activation_link', formValues.vpn_activation_link)
      formData.append('vpn_activation_qr_code_image', formValues.vpn_activation_qr_code_image)

      vpnConfirmResellerOrder.mutate(formData)
    }
  }

  const rejectResellerOrder = useMutation((rejectReason: string) => rejectOrder(id, rejectReason), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    },
  })

  const confirmReject = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to reject this order!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, reject it!',
    })

    if (result.isConfirmed) {
      const { value: rejectReason } = await Swal.fire({
        title: 'Enter reject reason:',
        input: 'text',
        inputPlaceholder: 'Type reject reason',
        showCancelButton: true,
      })

      if (rejectReason) {
        rejectResellerOrder.mutate(rejectReason)
      } else {
        console.log('User clicked Cancel for reject reason')
      }
    }
  }

  const setOrderToUnderProcess = useMutation(() => setOrderUnderProcess(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    },
  })

  const handleUnderProcess = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to set this order as Under Process!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, set it!',
    })

    if (result.isConfirmed) {
      setOrderToUnderProcess.mutate()
    }
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <FormattedMessage id='TABLE.GENERAL.ACTION' />
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {status !== 1 && (
          <>
           {!isVpnOrder && (
              <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={async () => await confirmingOrder()}>
                <FormattedMessage id='TABLE.GENERAL.CONFIRM' />
              </a>
            </div>
            )}
            
            {isVpnOrder && (
              <div className='menu-item px-3'>
                <a className='menu-link px-3' onClick={async () => await confirmingVpnOrder()}>
                  <FormattedMessage id='TABLE.GENERAL.CONFIRM_VPN' defaultMessage={"Confirm VPN"} />
                </a>
              </div>
            )}
          </>
        )}
        {(status === 0 || status === 3) && (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={async () => await confirmReject()}>
              <FormattedMessage id='TABLE.GENERAL.REJECT' />
            </a>
          </div>
        )}
        {status !== 1 && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-orders-table-filter='delete_row'
              onClick={async () => await confirmDelete()}
            >
              <FormattedMessage id='TABLE.GENERAL.DELETE' />
            </a>
          </div>
        )}
        {status !== 3 && status !== 1 && (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={async () => await handleUnderProcess()}>
              <FormattedMessage id='TABLE.GENERAL.UNDERPROCESS' />
            </a>
          </div>
        )}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { OrderActionsCell }
