import {useListView} from '../../core/ListViewProvider'
import {CurrenciesListToolbar} from './CurrencyListToolbar'
import {CurrenciesListGrouping} from './CurrenciesListGrouping'
import {CurrenciesListSearchComponent} from './CurrenciesListSearchComponent'

const CurrenciesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CurrenciesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <CurrenciesListGrouping /> : <CurrenciesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CurrenciesListHeader}
