import { FormattedMessage } from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ProvincesListFilter} from './ProvincesListFilter'

const ProvincesListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddProvinceModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-province-table-toolbar='base'>
      <ProvincesListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        <FormattedMessage id='TABLE.GENERAL.EXPORT' />      </button>
      {/* end::Export */}

      {/* begin::Add province */}
      <button type='button' className='btn btn-primary' onClick={openAddProvinceModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id='PROVINCE.TABLE.CREATEPROVINCE' />
      </button>
      {/* end::Add province */}
    </div>
  )
}

export {ProvincesListToolbar}
