import {useListView} from '../../core/ListViewProvider'
import {DistrictsListToolbar} from './DistrictListToolbar'
import {DistrictsListGrouping} from './DistrictsListGrouping'
import {DistrictsListSearchComponent} from './DistrictsListSearchComponent'

const DistrictsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <DistrictsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <DistrictsListGrouping /> : <DistrictsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {DistrictsListHeader}
