import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ServiceCategoriesListHeader} from './components/header/ServiceCategoriesListHeader'
import {ServiceCategoriesTable} from './table/ServiceCategoriesTable'
import {ServiceCategoryEditModal} from './service-category-edit-modal/ServiceCategoryEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const ServiceCategoriesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ServiceCategoriesListHeader />
        <ServiceCategoriesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ServiceCategoryEditModal />}
    </>
  )
}

const ServiceCategoriesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ServiceCategoriesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ServiceCategoriesListWrapper}
