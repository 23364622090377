import React, {useState, useEffect} from 'react'
import { onMessageListener, requestForTokenAndPermission } from '../firebase';
import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from '../../modules/auth';

const Notification = () => {
  const {currentUser} = useAuth()
  
  const [notification, setNotification] = useState({title: '', body: ''});
  const notify = () =>  toast(<ToastDisplay/>,{position:'bottom-left',duration:4000});
  function ToastDisplay() {
    return (
<div className="alert bg-light-primary border border-primary d-flex flex-column flex-sm-row p-5 mb-10">
  <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
    <h5 className="mb-1">{notification.title}</h5>
    <span>{notification.body}</span>
  </div>
</div>
    );
  };

  useEffect(() => {
    if (notification?.title ){
     notify()
    }
  }, [notification])

  // requestForTokenAndPermission(currentUser?.id);

  onMessageListener()
    .then((payload) => {
      setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
    })
    .catch((err) => console.log('failed: ', err));

  return (
     <Toaster/>
  )
}

export default Notification