import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'
import { Reseller } from '../resellers-list/core/_models'

type Props = {
    isResellerLoading: boolean
    reseller: Reseller | undefined
    subResellers: Reseller[] // Add subResellers to Props
}

export const ResellerView: React.FC<Props> = ({ reseller, isResellerLoading, subResellers }) => {
    const params = useParams<{ id: string }>()
    return (
        <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>
                            <FormattedMessage id='RESELLER.VIEW.SUBRESELLERSLIST' />
                        </h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    {/* Display all sub-resellers of this reseller */}
                    <ul className='list-group'>
                        {subResellers.length > 0 ? (
                            subResellers.map((subReseller) => (
                                <li key={subReseller.id} className='list-group-item'>
                                    <Link to={`/admin/pages/resellers/view/${subReseller.id}`}>
                                        {subReseller.reseller_name}
                                    </Link>
                                </li>
                            ))
                        ) : (
                            <p>No sub-resellers found.</p>
                        )}
                    </ul>
                </div>
            </div>

            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>
                            <FormattedMessage id='RESELLER.VIEW.PROFILEDETAILS' />
                        </h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            <FormattedMessage id='RESELLER.FORM.INPUT.RESELLERNAME' />
                        </label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{reseller?.reseller_name}</span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            <FormattedMessage id='RESELLER.FORM.INPUT.JOINDATE' />
                        </label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                                {dayjs(reseller?.created_at).format('MMMM DD, YYYY HH:mm:ss')}
                            </span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            <FormattedMessage id='RESELLER.FORM.INPUT.CONTACTNAME' />
                        </label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{reseller?.contact_name}</span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            <FormattedMessage id='RESELLER.TABLE.COLUMN.PHONE' />
                            <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Phone number must be active'
                            ></i>
                        </label>

                        <div className='col-lg-8 d-flex align-items-center'>
                            <span className='fw-bolder fs-6 me-2'>{reseller?.phone}</span>

                            <span className='badge badge-success'>
                                <FormattedMessage id='RESELLER.VIEW.VERIFIED' />
                            </span>
                        </div>
                    </div>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            <FormattedMessage id='RESELLER.FORM.INPUT.RESELLERNAME' />
                        </label>

                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>
                                <FormattedMessage id='RESELLER.TABLE.COLUMN.EMAIL' />
                                {reseller?.email}
                            </span>
                        </div>
                    </div>

                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                        <KTSVG
                            path='icons/duotune/general/gen044.svg'
                            className='svg-icon-2tx svg-icon-warning me-4'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
