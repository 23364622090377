import {useListView} from '../../core/ListViewProvider'
import {ServiceCategorySubTypesListToolbar} from './ServiceCategorySubTypeListToolbar'
import {ServiceCategorySubTypesListGrouping} from './ServiceCategorySubTypesListGrouping'
import {ServiceCategorySubTypesListSearchComponent} from './ServiceCategorySubTypesListSearchComponent'

const ServiceCategorySubTypesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ServiceCategorySubTypesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ServiceCategorySubTypesListGrouping /> : <ServiceCategorySubTypesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ServiceCategorySubTypesListHeader}
