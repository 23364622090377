import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ServiceCategorySubTypesListHeader} from './components/header/ServiceCategorySubTypesListHeader'
import {ServiceCategorySubTypesTable} from './table/ServiceCategorySubTypesTable'
import { ServiceCategorySubTypeEditModal } from './ServiceCategorySubType-edit-modal/ServiceCategorySubTypeEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const ServiceCategorySubTypesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ServiceCategorySubTypesListHeader />
        <ServiceCategorySubTypesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ServiceCategorySubTypeEditModal />}
    </>
  )
}

const ServiceCategorySubTypesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ServiceCategorySubTypesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ServiceCategorySubTypesListWrapper}
