/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Advertisement } from '../../core/_models'

type Props = {
  advertisement: Advertisement
}

const AdvertisementInfoCell: FC<Props> = ({ advertisement }) =>{
  return(
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className=' me-3'>
        <a href='#'>
          {advertisement.ad_slider_image_url ? (
            <div className='symbol-label'>
              <img src={`${advertisement.ad_slider_image_url}`} alt={advertisement.advertisement_title} className='w-25' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${advertisement.advertisement_title}`,
                `text-${advertisement.advertisement_title}`
              )}
            >
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {/* {advertisement.ad_slider_image_url} */}
        </a>
        {/* <span>{advertisement.ad_slider_image_url}</span> */}
      </div>
    </div>
  )
}

export { AdvertisementInfoCell }
