import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {DistrictsListHeader} from './components/header/DistrictsListHeader'
import {DistrictsTable} from './table/DistrictsTable'
import {DistrictEditModal} from './district-edit-modal/DistrictEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const DistrictsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <DistrictsListHeader />
        <DistrictsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <DistrictEditModal />}
    </>
  )
}

const DistrictsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DistrictsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DistrictsListWrapper}
