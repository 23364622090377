// @ts-nocheck
import {Column} from 'react-table'
import {CountryInfoCell} from './CountryInfoCell'
import {CountryLastLoginCell} from './CountryLastLoginCell'
import {CountryTwoStepsCell} from './CountryTwoStepsCell'
import {CountryActionsCell} from './CountryActionsCell'
import {CountrySelectionCell} from './CountrySelectionCell'
import {CountryCustomHeader} from './CountryCustomHeader'
import {CountrySelectionHeader} from './CountrySelectionHeader'
import {Country} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const countriesColumns: ReadonlyArray<Column<Country>> = [
  {
    Header: (props) => <CountrySelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <CountrySelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CountryCustomHeader tableProps={props} title={<FormattedMessage id='COUNTRY.TABLE.COLUMN.COUNTRYNAME' />} className='min-w-125px' />,
    id: 'country_name',
    accessor:'country_name',
    Cell: ({...props}) => <CountryInfoCell country={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CountryCustomHeader tableProps={props} title={<FormattedMessage id='COUNTRY.TABLE.COLUMN.COUNTRYTELECOMCODE' />} className='min-w-125px' />,
    id: 'country_telecom_code',
    accessor:'country_telecom_code',
  },
  {
    Header: (props) => <CountryCustomHeader tableProps={props} title={<FormattedMessage id='COUNTRY.FORM.INPUT.CURRENCY' />} className='min-w-125px' />,
    accessor: 'currency.name', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  {
    Header: (props) => <CountryCustomHeader tableProps={props} title={<FormattedMessage id='COUNTRY.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
    accessor: 'language.language_name', // Access the currency name from the nested structure.
    Cell: ({ value }) => value, // Display the currency name.
  },
  // {
  //   Header: (props) => <CountryCustomHeader tableProps={props} title={<FormattedMessage id='COUNTRY.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <CountryCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CountryActionsCell id={props.data[props.row.index].id} />,
  },
]

export {countriesColumns}
