import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialBundle, Bundle } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { BundlesListLoading } from '../components/loading/BundlesListLoading'
import { createBundle, updateBundle } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import { Company, Service } from '../../../service/services-list/core/_models'
import { ServiceCategory } from '../../../service-category/service-categories-list/core/_models'
import axios from 'axios'
import { Currency } from '../../../currency/currencies-list/core/_models'

type Props = {
  isBundleLoading: boolean
  bundle: Bundle
}

const editBundleSchema = Yup.object().shape({
  bundle_title: Yup.string()
    .required('Bundle title is required'),

  bundle_description: Yup.string()
    .required('Bundle description is required'),

  validity_type: Yup.string()
    .required('Validity type is required'),
  admin_buying_price: Yup.number()
    .required('Admin buying price is required')
    .positive('Admin buying price must be a positive number'),

  buying_price: Yup.number()
    .required('Buying price is required')
    .positive('Buying price must be a positive number'),

  selling_price: Yup.number()
    .required('Selling price is required')
    .positive('Selling price must be a positive number'),
    amount: Yup.number().positive('Amount must be a positive number'),
  service_id: Yup.string()
    .required('Service name is required'),

  currency_id: Yup.string()
    .required('Currency is required'),

  profile_image_url: Yup.mixed(),
});


const BundleEditModalForm: FC<Props> = ({ bundle, isBundleLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const SERVICE_URL = `${API_URL}/admin/services`;
  const CURRENCY_URL = `${API_URL}/admin/currencies`;
  const intl = useIntl()
  // Function to handle image selection
  const [currencies, setCurrencies] = useState<[Currency]>();
  const [services, setServices] = useState<[Service]>();

  useEffect(() => {
    // Make the API request using Axios
    //get countries
    axios
      .get(CURRENCY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCurrencies(data.data.currencies);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    //get provinces
    axios
      .get(SERVICE_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setServices(data.data.services);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [bundleForEdit] = useState<Bundle>({
    ...bundle,
    bundle_name: bundle.bundle_name || initialBundle.bundle_name,
    bundle_title: bundle.bundle_title || initialBundle.bundle_title,
    bundle_description: bundle.bundle_description || initialBundle.bundle_description,
    validity_type: bundle.validity_type || initialBundle.validity_type,
    bundle_type: bundle.bundle_type || initialBundle.bundle_type,
    bundle_code: bundle.bundle_code || initialBundle.bundle_code,
    admin_buying_price: bundle.admin_buying_price || initialBundle.admin_buying_price,
    buying_price: bundle.buying_price || initialBundle.buying_price,
    selling_price: bundle.selling_price || initialBundle.selling_price,
    currency_id: bundle.currency_id || initialBundle.currency_id,
    bundle_image_url: bundle.bundle_image_url || initialBundle.bundle_image_url,
    service: bundle.service?.id || initialBundle.service?.id,
    service_id: bundle.service_id || initialBundle.service_id

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: bundleForEdit,
    validationSchema: editBundleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values", values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateBundle(values)
        } else {
          await createBundle(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_bundle_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_bundle_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_bundle_header'
          data-kt-scroll-wrappers='#kt_modal_add_bundle_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='BUNDLE.FORM.INPUT.BUNDLETITLE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'BUNDLE.FORM.PLACEHOLDER.BUNDLETITLE' })}
              {...formik.getFieldProps('bundle_title')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.bundle_title && formik.errors.bundle_title },
                {
                  'is-valid': formik.touched.bundle_title && !formik.errors.bundle_title,
                }
              )}
              type='text'
              name='bundle_title'
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}
            />
            {/* end::Input */}
            {formik.touched.bundle_title && formik.errors.bundle_title && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.bundle_title}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='BUNDLE.FORM.INPUT.BUNDLEDESCRIPTION' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'BUNDLE.FORM.PLACEHOLDER.BUNDLEDESCRIPTION' })}
              {...formik.getFieldProps('bundle_description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.bundle_description && formik.errors.bundle_description },
                {
                  'is-valid': formik.touched.bundle_description && !formik.errors.bundle_description,
                }
              )}
              type='text'
              name='bundle_description'
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}
            />
            {/* end::Input */}
            {formik.touched.bundle_description && formik.errors.bundle_description && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.bundle_description}</span>
              </div>
            )}
          </div>
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='BUNDLE.FORM.INPUT.VALIDITYTYPE' />
          </label>
            <select
              defaultValue={bundle.validity_type}
              {...formik.getFieldProps('validity_type')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.validity_type && formik.errors.validity_type },
                {
                  'is-valid': formik.touched.validity_type && !formik.errors.validity_type,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}

            ><option disabled selected >{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              <option value="unlimited"><FormattedMessage id='BUNDLE.FORM.INPUT.SELECT.VALIDITYTYPE.OPTION.UNLIMITED' /></option>
              <option value="daily"><FormattedMessage id='BUNDLE.FORM.INPUT.SELECT.VALIDITYTYPE.OPTION.DAILY' />
              </option>
              <option value="nightly"><FormattedMessage id='BUNDLE.FORM.INPUT.SELECT.VALIDITYTYPE.OPTION.NIGHTLY' />
              </option>
              <option value="weekly"><FormattedMessage id='BUNDLE.FORM.INPUT.SELECT.VALIDITYTYPE.OPTION.WEEKLY' />
              </option>
              <option value="monthly"><FormattedMessage id='BUNDLE.FORM.INPUT.SELECT.VALIDITYTYPE.OPTION.MONTHLY' />
              </option>
              <option value="yearly"><FormattedMessage id='BUNDLE.FORM.INPUT.SELECT.VALIDITYTYPE.OPTION.YEARLY' />
              </option>
            </select>
            {formik.touched.validity_type && formik.errors.validity_type && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_code}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}
                    {/* begin::Input group */}

                    <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='BUNDLE.FORM.INPUT.BUNDLETYPE' />
          </label>
            <select
              defaultValue={bundle.bundle_type}
              {...formik.getFieldProps('bundle_type')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.bundle_type && formik.errors.bundle_type },
                {
                  'is-valid': formik.touched.bundle_type && !formik.errors.bundle_type,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}

            ><option disabled selected >{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>

              <option value="credit"><FormattedMessage id='BUNDLE.FORM.INPUT.SELECT.BUNDLETYPE.OPTION.CREDIT' />
              </option>
              <option value="package"><FormattedMessage id='BUNDLE.FORM.INPUT.SELECT.BUNDLETYPE.OPTION.PACKAGE' />
              </option>

            </select>
            {formik.touched.bundle_type && formik.errors.bundle_type && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_code}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='BUNDLE.FORM.INPUT.AMOUNT' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'BUNDLE.FORM.PLACEHOLDER.AMOUNT' })}
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.amount && formik.errors.amount },
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
              type='text'
              name='amount'
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}
            />
            {/* end::Input */}
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.amount}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='BUNDLE.FORM.INPUT.ADMINBUYINGPRICE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'BUNDLE.FORM.PLACEHOLDER.ADMINBUYINGPRICE' })}
              {...formik.getFieldProps('admin_buying_price')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.admin_buying_price && formik.errors.admin_buying_price },
                {
                  'is-valid': formik.touched.admin_buying_price && !formik.errors.admin_buying_price,
                }
              )}
              type='text'
              name='admin_buying_price'
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}
            />
            {/* end::Input */}
            {formik.touched.admin_buying_price && formik.errors.admin_buying_price && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.admin_buying_price}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='BUNDLE.FORM.INPUT.BUYINGPRICE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'BUNDLE.FORM.PLACEHOLDER.BUYINGPRICE' })}
              {...formik.getFieldProps('buying_price')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.buying_price && formik.errors.buying_price },
                {
                  'is-valid': formik.touched.buying_price && !formik.errors.buying_price,
                }
              )}
              type='text'
              name='buying_price'
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}
            />
            {/* end::Input */}
            {formik.touched.buying_price && formik.errors.buying_price && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.buying_price}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='BUNDLE.FORM.INPUT.SELLINGPRICE' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'BUNDLE.FORM.PLACEHOLDER.SELLINGPRICE' })}
              {...formik.getFieldProps('selling_price')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.selling_price && formik.errors.selling_price },
                {
                  'is-valid': formik.touched.selling_price && !formik.errors.selling_price,
                }
              )}
              type='text'
              name='selling_price'
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}
            />
            {/* end::Input */}
            {formik.touched.selling_price && formik.errors.selling_price && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.selling_price}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='BUNDLE.FORM.INPUT.SERVICENAME' />
          </label>
            <select
              {...formik.getFieldProps('service_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.service_id && formik.errors.service_id },
                {
                  'is-valid': formik.touched.service_id && !formik.errors.service_id,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}

            ><option value='' defaultValue={""}>{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              {services && services.map(service => (
                <option value={service.id}>{`${service?.service_category?.category_name} - ${service?.company?.company_name}`}</option>
              ))}
            </select>
            {formik.touched.service_id && formik.errors.service_id && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.bundle_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='BUNDLE.FORM.INPUT.CURRENCY' />
          </label>
            <select
              {...formik.getFieldProps('currency_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.currency_id && formik.errors.currency_id },
                {
                  'is-valid': formik.touched.currency_id && !formik.errors.currency_id,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isBundleLoading}

            ><option value='' defaultValue={""}>{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              {currencies && currencies.map(currency => (
                <option value={currency.id}>{currency.name} - {currency.symbol}</option>
              ))}
            </select>
            {formik.touched.currency_id && formik.errors.currency_id && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.bundle_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-bundles-modal-action='cancel'
            disabled={formik.isSubmitting || isBundleLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-bundles-modal-action='submit'
            disabled={isBundleLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isBundleLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isBundleLoading) && <BundlesListLoading />}
    </>
  )
}

export { BundleEditModalForm }
