/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Company } from '../../core/_models'

type Props = {
  company: Company
}

const CompanyInfoCell: FC<Props> = ({ company }) =>{
  return(
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className=' me-3'>
        <a href='#'>
          {company.company_logo ? (
            <div className='symbol-label'>
              <img src={`${company.company_logo}`} alt={company.company_name} className='w-25' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${company.company_name}`,
                `text-${company.company_name}`
              )}
            >
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {/* {company.company_logo} */}
        </a>
        {/* <span>{company.company_logo}</span> */}
      </div>
    </div>
  )
}

export { CompanyInfoCell }
