// @ts-nocheck
import {Column} from 'react-table'
import {TransactionInfoCell} from './TransactionInfoCell'
import {TransactionSelectionCell} from './TransactionSelectionCell'
import {TransactionCustomHeader} from './TransactionCustomHeader'
import {TransactionSelectionHeader} from './TransactionSelectionHeader'
import {Transaction} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import dayjs from 'dayjs'

const transactionsColumns: ReadonlyArray<Column<Transaction>> = [
  {
    Header: (props) => <TransactionSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TransactionSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TRANSACTION.TABLE.COLUMN.RESELLERNAME' />}
        className='min-w-125px'
      />
    ),
    accessor: 'reseller.reseller_name',
    Cell: ({...props}) => <TransactionInfoCell transaction={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TRANSACTION.TABLE.COLUMN.AMOUNT' />}
        className='min-w-125px'
      />
    ),
    accessor: 'amount',
  },
  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TRANSACTION.TABLE.COLUMN.CURRENCY' />}
        className='min-w-125px'
      />
    ),
    accessor: 'reseller.user.currency.symbol',
  },
  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TRANSACTION.TABLE.COLUMN.BUNDLETITLE' />}
        className='min-w-125px'
      />
    ),
    accessor: 'order.bundle.bundle_title',
    Cell: ({value}) => (
      <span>
        {value ? value : 'X'}
      </span>
    ),
  },
  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TRANSACTION.TABLE.COLUMN.TRANSACTIONDATE' />}
        className='min-w-125px'
      />
    ),
    accessor: 'created_at',
    Cell: ({value}) => (
      <span>
        {dayjs(value).format('MMMM DD, YYYY HH:mm:ss')}
      </span>
    ),
  },
  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TRANSACTION.TABLE.COLUMN.STATUS' />}
        className='min-w-125px'
      />
    ),
    accessor: 'status',
    Cell: ({value}) => (
      value === 'credit' ? 
      <span className="badge badge-info">Amount Credited To Reseller</span> :
      <span className="badge badge-primary">Amount Debited From Reseller</span>
    ),
  },
  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TRANSACTION.TABLE.COLUMN.INITIATORTYPE' />}
        className='min-w-125px'
      />
    ),
    accessor: 'initiator_type',
    Cell: ({value}) => (
      <span>
        {value === 'admin' ? 'Admin' : 'Reseller'}
      </span>
    ),
  },
  {
    Header: (props) => (
      <TransactionCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TRANSACTION.TABLE.COLUMN.DESCRIPTION' />}
        className='min-w-200px'
      />
    ),
    accessor: 'transaction_reason',
  },
];

export {transactionsColumns}
