import {useQuery} from 'react-query'
import {ServiceCategorySubTypeEditModalForm} from './ServiceCategorySubTypeEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getServiceCategorySubTypeById} from '../core/_requests'

const ServiceCategorySubTypeEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: servicecategorysubtype,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-servicecategorysubtype-${itemIdForUpdate}`,
    () => {
      return getServiceCategorySubTypeById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <ServiceCategorySubTypeEditModalForm isServiceCategorySubTypeLoading={isLoading} servicecategorysubtype={{id: undefined}} />
  }

  if (!isLoading && !error && servicecategorysubtype) {
    console.log({servicecategorysubtype, },"its servicecategorysubtype")
    console.log(typeof servicecategorysubtype,"its servicecategorysubtype")
    return <ServiceCategorySubTypeEditModalForm isServiceCategorySubTypeLoading={isLoading} servicecategorysubtype={servicecategorysubtype} />
  }

  return null
}

export {ServiceCategorySubTypeEditModalFormWrapper}
