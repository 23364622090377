import {useListView} from '../../core/ListViewProvider'
import {ResellersListToolbar} from './ResellerListToolbar'
import {ResellersListGrouping} from './ResellersListGrouping'
import {ResellersListSearchComponent} from './ResellersListSearchComponent'

const ResellersListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ResellersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ResellersListGrouping /> : <ResellersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ResellersListHeader}
